export const convertMovCassaToArrayOfObject = async (stringArray) => {
  const objectArray = [];
  await stringArray.forEach((item) => {
    objectArray.push({
      importo: item.mapValue.fields.importo.stringValue,
      infoMov: item.mapValue.fields.infoMov.stringValue,
      id: item.mapValue.fields.id.stringValue,
    });
  });

  return objectArray;
};
