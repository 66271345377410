import React from "react";
import { useState, useEffect } from "react";
import "./VoceCassaCard.scss";
import { addVoceCassaDelete, addVoceCassaSave } from "../../store/actions/handleHome";
import { useDispatch, useSelector } from "react-redux";
import { StyleColorObject } from "../../functions/StyleColorObject";
import Swal from "sweetalert2";
import IcoDelete from "../globalComponent/IcoDelete";
import IcoSave from "../globalComponent/IcoSave";
import ReactTooltip from "react-tooltip";

function VoceCassaCard({ id, nome, day }) {
  const { loadingVociCassa, arrayVociCassa } = useSelector((state) => state.homeReducers);

  const jsonCard = arrayVociCassa.filter((item) => item.alias === id);
  const percentualeQuadagno = Number(jsonCard[0].percentualeQuadagno);
  const valore = Number(jsonCard[0].valore);

  const [val, setVal] = useState(valore);

  useEffect(() => {
    setVal(valore);
  }, [valore]);

  const dispatch = useDispatch();

  const styles = StyleColorObject(val);

  const handleInput = (e) => {
    setVal(e.target.value);
    e.preventDefault();
  };

  const handleSave = () => {
    dispatch(addVoceCassaSave(id, Number(val), day));
  };

  const handleDelete = (e) => {
    dispatch(addVoceCassaDelete(id, day));
  };

  const handelConfirmDelete = () => {
    Swal.fire({
      title: `Sei sicuro di voler eliminare questa voce ??`,
      text: ` "${nome.toUpperCase()}" verra eliminata dalla lista delle voci`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00D100",
      cancelButtonColor: "#d33",
      confirmButtonText: "SI, ELIMINA !!",
      cancelButtonText: "NO",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(handleDelete);
      }
    });
  };

  return (
    <div className="voce-cassa-card">
      <div
        style={{ display: "flex" }}
        data-tip={
          Number(val) === 0
            ? ""
            : Number(percentualeQuadagno) === 0
            ? `non hai inserito una percentuale di guadagno <br /> quindi non è quantificabile`
            : `Il tuo guadagno è di ${(
                (Number(percentualeQuadagno) * Number(val)) /
                100
              ).toFixed(2)}€`
        }
        data-delay-show="200"
        data-for={id}
      >
        <div className="nome">
          <p>{nome}</p>
        </div>

        <input
          className="input-value"
          type="number"
          value={val}
          onChange={handleInput}
          style={styles}
          å
        />
      </div>
      <ReactTooltip multiline={true} id={id} />
      <IcoSave handleSave={!loadingVociCassa ? handleSave : null} />
      <IcoDelete handelConfirmDelete={!loadingVociCassa ? handelConfirmDelete : null} />
    </div>
  );
}

export default VoceCassaCard;
