import React, { useState } from "react";
import "../styles/ContactForm.css";
import * as SiIcons from "react-icons/si";
import axios from "axios";
import Swal from "sweetalert2";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";

function ContactForm() {
  const userToken = useSelector((state) => state.authReducer.token);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  //   const [name, setName] = useState("");
  //   const [email, setEmail] = useState("");
  //   const [message, setMessage] = useState("");

  const submitForm = async (e) => {
    e.preventDefault();
    if (formData.name.trim() === "") {
      Swal.fire({
        title: "Errore",
        text: "il campo 'nome' è vuoto o non valido",
        icon: "warning",
      });
      return;
    }
    if (formData.email.trim() === "") {
      Swal.fire("Errore", "il campo 'email' è vuoto o non valido", "warning");
      return;
    }
    if (formData.message.trim() === "") {
      Swal.fire("Errore", "il campo 'messagio' è vuoto o non valido", "warning");
      return;
    }
    try {
      await axios.post("https://submit-form.com/vkScKeBC", formData);
      setFormData({
        name: "",
        email: "",
        message: "",
      });
      Swal.fire("Success", "Richiesta Inviata con successo", "success");
    } catch (error) {
      Swal.fire("Errore", error.message, "error");
      console.log(error.message);
    }
  };
  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <div className="contact-form">
        <div className="head-contact">
          <span className="ico-ct">
            <SiIcons.SiMinutemailer />
          </span>
          <h2> Contattaci</h2>
        </div>
        <form action="">
          <input type="hidden" name="_append" value="false" />
          <label for="name">Nome</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Nome"
            required=""
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            required=""
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          <label for="message">Messaggio</label>
          <textarea
            id="message"
            name="message"
            placeholder="Messaggio"
            required=""
            value={formData.message}
            onChange={(e) => setFormData({ ...formData, message: e.target.value })}
          ></textarea>
          <button type="submit" onClick={submitForm}>
            Invia
          </button>
        </form>
      </div>
    </>
  );
}

export default ContactForm;
