import { dateFix0 } from "./dateFix0";

export const getDayDiffDate = async (date1, date2) => {
  // start fetch
  date1 = await dateFix0(date1);
  date2 = await dateFix0(date2);

  var x = new Date(date1);
  var y = new Date(date2);

  const diffInDays = Math.floor((x - y) / (1000 * 60 * 60 * 24));
  return diffInDays;
};
