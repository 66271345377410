import React from "react";
import * as MdIcons from "react-icons/md";
import "./ColumServiceVociCassa.css";
import ButtonAdd from "../globalComponent/ButtonAdd";

function ColumServiceVociCassa({
  value,
  style,
  styleAG,
  addFunction,
  renderBodyFunction,
  aggioGiornaliero,
}) {
  return (
    <div className="voci-cassa">
      <div>
        <div className="totale-cassa">
          <p className="head-tot-cassa">Aggio Giornaliero</p>
          <p className="tot-cassa" style={styleAG}>
            {aggioGiornaliero} €
          </p>
        </div>
        <div className="totale-cassa">
          <p className="head-tot-cassa">Toale Cassa</p>
          <p className="tot-cassa" style={style}>
            {value} €
          </p>
        </div>
        <p className="separatore"></p>
        <div className="head-voci-container">
          <p className="ico-head-cs">
            <MdIcons.MdLabelOutline />
          </p>
          <p className="head-voci-cassa">Voci Cassa</p>

          <ButtonAdd
            styleClass={"btn-add-credito"}
            addFunction={addFunction}
            type={true}
            dataTip={
              "Premi questo tasto se <br /> vuoi aggiungere una <br /> voce alla tua cassa"
            }
            id={"3333"}
          />
        </div>
      </div>
      <div className="body-voci-cassa">{renderBodyFunction()}</div>
    </div>
  );
}

export default ColumServiceVociCassa;
