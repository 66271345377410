import { doc, collection, getDocs, query } from "@firebase/firestore";
import db from "../Firebase-config";

export const searchRubrica = async () => {
  let arrayRubrica = [];
  // riferimento univco del magazzino Utente
  const email = localStorage.getItem("email");
  const refMag = email.split("@");
  const addRefRubrica = doc(db, `${refMag[0]}.magazzino`, "rubrica"); // riferimento del db nello rubrica

  const q = query(collection(addRefRubrica, "rubrica"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    arrayRubrica.push({
      nome: doc._document.data.value.mapValue.fields.nome.stringValue,
      cognome: doc._document.data.value.mapValue.fields.cognome.stringValue,
      credititoTotale: doc._document.data.value.mapValue.fields.credititoTotale
        .doubleValue
        ? doc._document.data.value.mapValue.fields.credititoTotale.doubleValue
        : doc._document.data.value.mapValue.fields.credititoTotale.integerValue,
      storicoCrediti:
        doc._document.data.value.mapValue.fields.storicoCrediti.arrayValue.values.map(
          (item) => item.stringValue
        ),
      alias: doc._document.data.value.mapValue.fields.alias.stringValue,
    });
  });
  arrayRubrica.sort((a, b) => a.cognome.localeCompare(b.cognome));
  return arrayRubrica;
};
