import "./App.css";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Auth from "./pages/Auth";
import Logout from "./pages/Logout";
import Registrazione from "./pages/Registrazione";
import ListaCrediti from "./pages/ListaCrediti";
import ContactForm from "./pages/ContactForm";
import Report from "./pages/Report";
import PDFView from "./pages/Pdfview";
import Price from "./pages/Price";
import VoiceSetting from "./pages/VoiceSetting";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { authCheck } from "./store/actions/handelAuth";
import { PlanData } from "../src/components/priceContainer/PlanData";

import Help from "./pages/Help";

function App() {
  // const userToken = useSelector((state) => state.authReducer.token);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authCheck());
  }, [dispatch]);

  return (
    <>
      {/* <Offline>
        <OfflinePage></OfflinePage>
      </Offline> */}

      {/* <Online> */}
      {/* {userToken ? <Navbar isAutenticated={userToken} /> : null} */}

      <Switch>
        {" "}
        {/* <Route path="*" component={PageNotFound} /> */}
        <Route path="/" exact component={Home} />
        <Route path="/auth" component={Auth} />
        <Route path="/logout" component={Logout} />
        <Route path="/lista-crediti" component={ListaCrediti} />{" "}
        <Route path="/report/pdf" component={PDFView} />
        <Route path="/report" component={Report} />
        <Route path="/registrazione" component={Registrazione} />
        <Route path="/help" component={Help} />
        <Route path="/contactform" component={ContactForm} />
        <Route path="/voice-setting" component={VoiceSetting} />
        <Route path="/price" component={Price} />
        {PlanData.map((item) => {
          return (
            <Route
              path={item.path}
              component={() => {
                window.location.href = item.link;

                return null;
              }}
            />
          );
        })}
      </Switch>
      {/* </Online> */}
    </>
  );
}

export default App;
