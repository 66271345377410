import { doc, getDoc } from "@firebase/firestore";
import db from "../Firebase-config";

export const updateTotal = async (objectArray) => {
  // riferimento univco del magazzino Utente
  const email = localStorage.getItem("email");
  const refMag = email.split("@");
  const addRefRubrica = doc(db, `${refMag[0]}.magazzino`, "rubrica"); // riferimento del db nello rubrica

  await objectArray.forEach(async (element) => {
    const addRefNomi = doc(addRefRubrica, "rubrica", element.id); // rif biglietti non ancora venduti
    let dataName = await getDoc(addRefNomi);
    const nameExist = dataName.exists();
    if (nameExist) {
      element.credititoTotale = String(dataName.data().credititoTotale.toFixed(2));
    }
  });

  return objectArray;
};
