export const ReplaceTotalToDuplicateName = async (
  objectArray,
  cognome,
  nome,
  importo,
  credititoTotale,
  time
) => {
  let eraPresente = false;
  await objectArray.forEach(async (element) => {
    if (element.id === `${cognome}-${nome}`) {
      eraPresente = true;
      element.totaleCG = String((Number(element.totaleCG) + Number(importo)).toFixed(2));
      element.credititoTotale = String(credititoTotale);
      element.time = time;
    }
  });
  return [eraPresente, objectArray];
};
