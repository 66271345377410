import React from "react";
import ReactTooltip from "react-tooltip";
import "./CardFooterReport.css";

function CardFooterReport({ value, style }) {
  return (
    <>
      <div
        className="head-card-voce"
        style={style}
        data-tip={
          Number(value.totVoce) === 0
            ? null
            : Number(value.perQ) === 0
            ? `non hai inserito una percentuale di guadagno <br /> quindi non è quantificabile`
            : `Il tuo guadagno è di ${(
                (Number(value.perQ) * Number(value.totVoce)) /
                100
              ).toFixed(2)}€`
        }
        data-delay-show="200"
        data-for={value.alias}
      >
        <p>{value.totVoce} €</p>
      </div>
      <ReactTooltip multiline={true} id={value.alias} />
    </>
  );
}

export default CardFooterReport;
