export const RemoveArrayObjectoForID = async (objectArray, id) => {
  const result = [];
  await objectArray.forEach(async (element) => {
    if (element.id !== id) {
      result.push(element);
    }
  });

  return result;
};
