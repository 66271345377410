import { Document, Page, Text, View, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import { DataTranform } from "../../functions/DateTranform";
// Create styles

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "black",
    margin: "100 100 100 100",
  },
  sectionHead: {
    margin: "100 0 100 0",
    padding: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderBottom: "10px solid black",
  },
  sectionBody: {
    margin: 2,
    padding: 1,
    display: "flex",
    flexDirection: "colum",
    justifyContent: "flex-start",
    alignItems: "start",
  },
  sectionData: {
    marginTop: 2,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "start",
  },
  sectionBodyRow: {
    marginTop: 2,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "start",
    borderBottom: "10px solid black",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  title: {
    margin: 4,
    fontSize: 100,
    textAlign: "center",
    // width: ,
    marginLeft: 200,
  },
  head: {
    margin: 4,
    fontSize: 50,
    width: 300,
    marginLeft: 2,
    backgroundColor: "yellow",
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    paddingTop: "30px",
    // borderRight: "10px solid black",
  },
  dataCol: {
    margin: 4,
    fontSize: 50,
    width: 300,
    marginLeft: 2,
    backgroundColor: "yellow",
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    paddingTop: "30px",
  },
  bodyP: {
    margin: 4,
    fontSize: 50,
    width: 300,
    marginLeft: 2,
    backgroundColor: "#ccffcc",
    color: "#008000",
    // border: "2px solid #008000",
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    paddingTop: "30px",
  },
  bodyN: {
    margin: 4,
    fontSize: 50,
    width: 300,
    marginLeft: 2,
    backgroundColor: "#ffcccc",
    color: "#FF0000",
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    paddingTop: "30px",
  },

  footer: {
    margin: 4,
    fontSize: 10,
    textAlign: "justify",
    width: 300,
    marginLeft: 40,
  },
  textGiacenze: {
    margin: 4,
    fontSize: 14,
    textAlign: "justify",
    width: 150,
  },
  textVmg: {
    margin: 4,
    fontSize: 14,
    textAlign: "justify",
    width: 70,
  },
  textPo: {
    margin: 4,
    fontSize: 14,
    textAlign: "justify",
    width: 150,
  },
  divClass: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

// Create Document Component
function CreatePdf({
  arrayReport,
  arrVociCassa,
  arrTotVociCassa,
  dataInizioStatistiche,
  dataFineStatistiche,
}) {
  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="4A0" style={styles.page} orientation={"landscape"}>
          <View style={styles.section}>
            <Text style={styles.title}>
              Report dal {dataInizioStatistiche} al {dataFineStatistiche}
            </Text>
          </View>

          <View style={styles.sectionHead}>
            <Text style={styles.head}>DATA </Text>
            {arrVociCassa.map((voce,i) => {
              return <Text key={i+'id'} style={styles.head}>{voce.nome} </Text>;
            })}
          </View>

          <View style={styles.sectionBody}>

            {arrayReport.map((item,i) => {
              return (
                <View key={i+'pollos'} style={styles.sectionBodyRow}>
                  <Text style={styles.dataCol}>{DataTranform(item.data)} </Text>
                  {item.arrayDay.arrayClean.map((voce,id) => {
                    return (
                      <Text key={id+'pollo'} style={voce.valore >= 0 ? styles.bodyP : styles.bodyN}>
                        {voce.valore}{" "}
                      </Text>
                    );
                  })}
                </View>
              );
            })}
          </View>

          <View style={styles.sectionHead}>
            <Text style={styles.dataCol}>TOT </Text>
            {arrTotVociCassa.map((voce,i) => {
              return (
                <Text key={i+'item'} style={voce.totVoce >= 0 ? styles.bodyP : styles.bodyN}>
                  {voce.totVoce}{" "}
                </Text>
              );
            })}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default CreatePdf;
