import React from "react";
import * as AiIcons from "react-icons/ai";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import { StyleColorObject } from "../../functions/StyleColorObject";
import Swal from "sweetalert2";
import "./SingleMovCassa.css";
import IcoDelete from "../globalComponent/IcoDelete";
function SingleMovCassa({ mov, day, deleteFunction }) {
  const dispatch = useDispatch();
  const styles = StyleColorObject(mov.importo);

  const handelDelete = async () => {
    dispatch(deleteFunction(mov.id, mov.importo, day));
  };

  const handelConfirmDelete = () => {
    Swal.fire({
      title: `Sei sicuro di voler eliminare questo elemento ??`,
      text: ` "${mov.infoMov.toUpperCase()}" di "${mov.importo}€" verra eliminato`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00D100",
      cancelButtonColor: "#d33",
      confirmButtonText: "SI, ELIMINA !!",
      cancelButtonText: "NO",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(handelDelete);
      }
    });
  };
  return (
    <div className="t-body-mov-cassa">
      <p className="t-body-mov" style={styles}>
        {mov.importo} €
      </p>
      <p
        className="ico-ora-mov"
        data-tip={mov.infoMov}
        data-delay-show="200"
        data-type="info"
      >
        <AiIcons.AiOutlineFieldTime />
      </p>
      <ReactTooltip multiline={true} />

      <IcoDelete handelConfirmDelete={handelConfirmDelete} />
    </div>
  );
}

export default SingleMovCassa;
