import React from "react";
import Chart from "react-apexcharts";
import "./CardSingleStatsChart.css";

function CardSingleStatsChart({ option, name, percent }) {
  const tot = option.series[0].data.reduce((acc, curr) => acc + curr, 0);
  const mediaGior = (tot / option.series[0].data.length).toFixed(2);
  //  calcolare netto del quandagno in base alla percenutale
  //   const netto = (mediaGior * percent) / 100;
  return (
    <div className="div_chart_single_stats">
      <p style={{ textAlign: "center", fontSize: "20px" }}>{name}</p>
      <Chart options={option.options} series={option.series} type="line" width={350} />
      <p>Media Giornaliera: {mediaGior}€</p>
    </div>
  );
}

export default CardSingleStatsChart;
