import { doc, collection, getDocs, query } from "@firebase/firestore";
import db from "../Firebase-config";

export const searchVociCassa = async () => {
  let arrayVociCassa = [];
  // riferimento univco del magazzino Utente
  const email = localStorage.getItem("email");
  const refMag = email.split("@");
  const addRefRubrica = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello rubrica

  const q = query(collection(addRefRubrica, "lista-voci-cassa"));
  const querySnapshot = await getDocs(q);
  const position = querySnapshot.docs;
  position.forEach((doc) => {
    arrayVociCassa.push({
      nome: doc._document.data.value.mapValue.fields.nome.stringValue,
      alias: doc._document.data.value.mapValue.fields.alias.stringValue,
      percentualeQuadagno:
        doc._document.data.value.mapValue.fields.percentualeQuadagno.integerValue,
      valore: 0,
    });
  });
  return arrayVociCassa;
};
