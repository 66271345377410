import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { CeckConnection } from "../functions/ConnectionStatius";
import Swal from "sweetalert2";
import "../styles/ListaCrediti.css";
import { useDispatch } from "react-redux";
import { addNameRubrica, fetchRubrica } from "../store/actions/handelListaCrediti";
import { useSelector } from "react-redux";
import { errorReset } from "../store/actions/handleHome";

import * as ImIcons from "react-icons/im";
import * as BsIcons from "react-icons/bs";
import CreditCard from "../components/listaCreditiComponent/CreditCard";
import { StyleColorObject } from "../functions/StyleColorObject";
import HeadTot from "../components/globalComponent/HeadTot";
import { stateChange } from "../functions/stateChange";
import Navbar from "../components/Navbar";
import soudError from "../sound/error.wav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/Price.scss";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

function ListaCrediti() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const userToken = useSelector((state) => state.authReducer.token);
  const [input, setInput] = useState("");
  const [sort, setSort] = useState("surname");
  const [surname, setSurname] = useState(true);
  const [name, setName] = useState(true);
  const [importo, setImporto] = useState(true);

  let arrayRubrica = useSelector((state) => state.listaCreditiReducer.arrayRubrica);

  const { totCrediti, storicoCrediti, error, message } = useSelector(
    (state) => state.listaCreditiReducer
  );

  const style = StyleColorObject(Number(totCrediti));

  useEffect(() => {
    // verifica connessione
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }

    //______
    if (token) {
      stateChange();
      dispatch(errorReset());
      dispatch(fetchRubrica());
    }
  }, [dispatch, token, sort]);

  // se non è presente il token allora il cliente  non è registrato oppure non è loggato
  if (!token) {
    return <Redirect to="/auth"></Redirect>;
  }

  // tost message errore
  const notifyError = () =>
    toast.error(message, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  // audio errore
  const audio = new Audio(soudError);
  audio.loop = false;
  const playError = () => {
    audio.loop = false;
    audio.play();
  };

  // resetta errori
  const alterShow = () => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }
    //-----
    dispatch(errorReset());
  };

  const handleAddNameRubrica = async () => {
    const { value: formValues } = await Swal.fire({
      title: "NUOVO NOME",
      html:
        '<input id="swal-input1" class="swal2-input" placeholder="cognome">' +
        '<input id="swal-input2" class="swal2-input" placeholder="nome">' +
        '<input id="swal-input3" class="swal2-input" type=number placeholder="0">',

      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
          document.getElementById("swal-input3").value,
        ];
      },
    });
    if (formValues) {
      if (!formValues[0]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Il campo del COGNOME è vuoto",
        });
        return;
      }
      if (!formValues[1]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Il campo del NOME è vuoto",
        });
        return;
      }
      if (!formValues[2]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Il campo del CREDITO è vuoto",
        });
        return;
      }
      dispatch(addNameRubrica(formValues[0].trim(), formValues[1].trim(), formValues[2]));
    }
  };

  const handleInput = (e) => {
    e.preventDefault();
    setInput(e.target.value);
  };

  const renderStoricoCrediti = () => {
    if (storicoCrediti.length === 0) {
      return;
    } else {
      return storicoCrediti.reverse().map((s) => {
        const style = StyleColorObject(s.importo);
        return (
          <div className="storico-render">
            <p>{s.data}</p>
            <p>{s.ora}</p>
            <p style={style}>{s.importo} €</p>
          </div>
        );
      });
    }
  };

  const renderRubrica = () => {
    if (sort === "surname") {
      arrayRubrica = arrayRubrica.sort((a, b) => a.cognome.localeCompare(b.cognome));
      if (!surname) {
        arrayRubrica = arrayRubrica.sort((b, a) => a.cognome.localeCompare(b.cognome));
      }
    }
    if (sort === "name") {
      arrayRubrica = arrayRubrica.sort((a, b) => a.nome.localeCompare(b.nome));
      if (!name) {
        arrayRubrica = arrayRubrica.sort((b, a) => a.nome.localeCompare(b.nome));
      }
    }
    if (sort === "import") {
      arrayRubrica = arrayRubrica.sort((a, b) =>
        String(a.credititoTotale).localeCompare(String(b.credititoTotale), undefined, {
          numeric: true,
        })
      );
      if (!importo) {
        arrayRubrica = arrayRubrica.sort((b, a) =>
          String(a.credititoTotale).localeCompare(String(b.credititoTotale), undefined, {
            numeric: true,
          })
        );
      }
    }
    return (
      arrayRubrica
        // eslint-disable-next-line array-callback-return
        .filter((f) => {
          if (input === "") {
            return f;
          } else if (
            f.nome.toLowerCase().includes(input.toLowerCase()) ||
            f.cognome.toLowerCase().includes(input.toLowerCase()) ||
            String(f.credititoTotale).toLowerCase().includes(input.toLowerCase())
          ) {
            return f;
          }
        })
        .map((item, index) => {
          return (
            <>
              <CreditCard item={item} index={index} />
            </>
          );
        })
    );
  };

  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <div className="container-lista-crediti" style={{ display: "flex" }}>
        <div className="lista-crediti">
          <HeadTot
            title={`Totale Crediti`}
            value={totCrediti.toFixed(2)}
            style={style}
            marginLeft={"0.5vw"}
            icon={<GroupAddIcon sx={{ color: "#fff", fontSize: "50px" }} />}
            // marginTop={"1vh"}
            // marginRight={"0.5vw"}
          />
          <div className="lista-credit-head">
            <input
              type="text"
              className="input-stampa"
              placeholder="inserisci il criterio di ricerca"
              onChange={handleInput}
              value={input}
            />
            <button className="btn-add-credito" onClick={handleAddNameRubrica}>
              <ImIcons.ImUserPlus />
            </button>
          </div>

          {/* <div className="lista-crediti-body">{renderRubrica()}</div> */}
          <div className="t-fm">
            <div className="local-bootstrap">
              <table class="table table-hover table-fm">
                <thead>
                  <tr>
                    {/* <th scope="col">#</th> */}
                    <th
                      className="col-fm"
                      scope="col"
                      onClick={() => {
                        setSort("surname");
                        setSurname(!surname);
                      }}
                    >
                      Cognome {surname ? <BsIcons.BsSortDownAlt /> : <BsIcons.BsSortUp />}
                    </th>
                    <th
                      className="col-fm"
                      scope="col"
                      onClick={() => {
                        setSort("name");
                        setName(!name);
                      }}
                    >
                      Nome {name ? <BsIcons.BsSortDownAlt /> : <BsIcons.BsSortUp />}
                    </th>
                    <th
                      className="col-fm"
                      scope="col"
                      onClick={() => {
                        setSort("import");
                        setImporto(!importo);
                      }}
                    >
                      Importo {importo ? <BsIcons.BsSortDownAlt /> : <BsIcons.BsSortUp />}
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>

                <tbody className="t-body-fm">{renderRubrica()}</tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="storico-crediti-container">
          <div className="storico-crediti-head">
            <h2>STORICO CREDITI</h2>
          </div>

          <div className="storico-crediti-body"> {renderStoricoCrediti()}</div>
        </div>
        {error ? (playError(), notifyError(), alterShow()) : null}
        <ToastContainer
          position="top-letf"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}

export default ListaCrediti;
