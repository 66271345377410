export const convertToArrayOfObject = async (stringArray) => {
  const objectArray = [];
  await stringArray.forEach((item) => {
    objectArray.push({
      id: item.mapValue.fields.id.stringValue,
      cognome: item.mapValue.fields.cognome.stringValue.toUpperCase(),
      nome: item.mapValue.fields.nome.stringValue.toUpperCase(),
      totaleCG: item.mapValue.fields.totaleCG.stringValue,
      time: item.mapValue.fields.time.stringValue,
      credititoTotale: item.mapValue.fields.credititoTotale.stringValue,
    });
  });

  return objectArray;
};
