import React from "react";
import HeadTot from "../globalComponent/HeadTot";
import "./ColumService.css";
import "./ColumServiceCredit.css";
import ReactTooltip from "react-tooltip";
import ButtonAdd from "../globalComponent/ButtonAdd";
import { Button, Divider } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AddIcon from "@mui/icons-material/Add";
function ColumService({
  titleHead,
  ico,
  title,
  value,
  style,
  addFunction,
  renderBodyFunction,
  dataTip,
  dataTipButton,
  icon,
}) {
  return (
    <div className="movimenti-cassa">
      <div className="div_">
        <HeadTot
          title={titleHead}
          value={value}
          // style={style}
          icon={icon}
        />
        {/* <Divider /> */}
        <div className="head-movimenti">
          {/* <p className="ico-head-cs">{ico}</p>
          <p
            className="head-movimenti-cassa"
            data-tip={dataTip}
            data-delay-show="200"
            data-for={title}
          >
            {title}
          </p>
          <ReactTooltip multiline={true} id={title} /> */}
          {/* <ButtonAdd
            styleClass={"btn-add-credito"}
            addFunction={addFunction}
            type={true}
            dataTip={dataTipButton}
            id={"3333"}
          /> */}
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            sx={{ fontSize: "12px", bgcolor: "#2b3041" }}
            onClick={addFunction}
          >
            Aggiungi {title}
          </Button>
        </div>
        <Divider />
        <div className="body-mov-cassa">{renderBodyFunction()}</div>
      </div>
    </div>
  );
}

export default ColumService;
