import { initializeApp } from "firebase/app";
import { getFirestore, enableIndexedDbPersistence } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD9PpC8w1M3KNcbS672Yiimt8yy9L9z8s0",
  authDomain: "gstionale-tabacchi.firebaseapp.com",
  projectId: "gstionale-tabacchi",
  storageBucket: "gstionale-tabacchi.appspot.com",
  messagingSenderId: "625808380788",
  appId: "1:625808380788:web:e05fe67dd34cae82581221",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code === "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
  console.log("persistance error", err.code);
});

export default db;
