import * as actionTypes from "../actions/handelReport";

const initialState = {
  // error message
  loading: false,
  error: false,
  message: "",
  // store const
  arrVociCassa: [],
  arrayReport: [],
  arrTotVociCassa: [],
  dataInizioStatistiche: "",
  dataFineStatistiche: "",
  totNetto: 0,
  option: {
    series: [44, 55, 13, 43, 22],
    options: {
      chart: {
        width: 500,
        type: "pie",
      },
      labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  },
  option_spese: {
    series: [
      {
        name: "Spese",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
        labels: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: "€",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " €";
          },
        },
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REPORT_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        //action
        arrayReport: action.arrayReport,
        arrVociCassa: action.arrVociCassa,
        arrTotVociCassa: action.arrTotVociCassa,
        dataInizioStatistiche: action.dataInizioStatistiche,
        dataFineStatistiche: action.dataFineStatistiche,
        totNetto: action.totNetto,
        //
        loading: false,
        error: false,
        option: action.option,
        option_spese: action.option_spese,
      };
    case actionTypes.FETCH_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
