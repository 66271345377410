import React from "react";
import HeadTot from "../globalComponent/HeadTot";
import * as TiIcons from "react-icons/ti";
import * as ImIcons from "react-icons/im";
import * as BsIcons from "react-icons/bs";
import "./ColumServiceCredit.css";
import "../../styles/Price.scss";
import ReactTooltip from "react-tooltip";
import ButtonAdd from "../globalComponent/ButtonAdd";
import { Button, Divider } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AddIcon from "@mui/icons-material/Add";

function ColumServiceCerdit({
  value,
  style,
  addCreditFunction,
  addNameFunction,
  renderBodyFunction,
}) {
  return (
    <div className="crediti">
      <div className="head-mobile-crediti">
        {/* <div className="btn-container">
          <button className="btn-add-credito" onClick={addCreditFunction}>
            <TiIcons.TiPlus />
          </button>
          <button className="btn-add-credito" onClick={addNameFunction}>
            <ImIcons.ImUserPlus />
          </button>
        </div> */}
        <HeadTot
          title={`Totale Crediti`}
          value={value}
          style={style}
          icon={<GroupAddIcon sx={{ color: "#fff", fontSize: "50px" }} />}
        />
      </div>

      <div className="div_">
        <div className="mobile-none">
          <HeadTot
            title={`Totale Crediti`}
            value={value}
            // style={style}
            icon={<GroupAddIcon sx={{ color: "#fff", fontSize: "50px" }} />}
          />
        </div>
        {/* <Divider /> */}

        <div className="head-crediti">
          <div className="mobile_btn">
            <Button
              variant="contained"
              endIcon={<AddIcon />}
              sx={{ fontSize: "12px", marginRight: "10px", bgcolor: "#2b3041" }}
              onClick={addCreditFunction}
            >
              Aggiungi Credito
            </Button>

            <Button
              variant="contained"
              endIcon={<AddIcon />}
              sx={{ fontSize: "12px", bgcolor: "#2b3041" }}
              onClick={addNameFunction}
            >
              Aggiungi nome in rubrica
            </Button>
          </div>
        </div>
        <Divider />

        <div className="body-crediti">{renderBodyFunction()}</div>
      </div>
    </div>
  );
}

export default ColumServiceCerdit;
