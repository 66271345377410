import React from "react";
import * as AiIcons from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import { StyleColorObject } from "../../functions/StyleColorObject";
import "./SingleCreditCard.css";
function SignleCreditCard({ person }) {
  const stylesTCG = StyleColorObject(person.totaleCG);
  const stylesTCT = StyleColorObject(person.credititoTotale);

  return (
    <div className="t-body">
      <p className="t-body-nome-cognome">{person.cognome + " " + person.nome}</p>
      <p className="t-body-cg" style={stylesTCG}>
        {person.totaleCG} €
      </p>
      <p className="t-body-ct" style={stylesTCT}>
        {person.credititoTotale} €
      </p>
      <p
        className="ico-ora-credit"
        data-tip={`${person.time}`}
        data-delay-show="200"
        data-type="info"
      >
        <AiIcons.AiOutlineFieldTime />
      </p>

      <ReactTooltip multiline={true} />
    </div>
  );
}

export default SignleCreditCard;
