import React from "react";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import "./IcoSave.css";
import ReactTooltip from "react-tooltip";

function IcoSave({ handleSave }) {
  return (
    <div
      className="save-ico-container"
      data-tip={`Premi per salvare il totale <br /> di questo elemento`}
      data-delay-show="200"
    >
      <p className="save-ico">
        {/* <FiIcons.FiPlus onClick={handleSave} /> */}
        <MdIcons.MdOutlineSaveAlt onClick={handleSave} />
      </p>

      <p className="save-ico-x">
        <BsIcons.BsCircleFill onClick={handleSave} />
      </p>
      <ReactTooltip multiline={true} />
    </div>
  );
}

export default IcoSave;
