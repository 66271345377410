import { searchRubrica } from "../../functions/searchRubrica";
import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "@firebase/firestore";

import db from "../../Firebase-config";

const FETCH_RUBRICA_START = "FETCH_RUBRICA_START";
const FETCH_RUBRICA_SUCCESS = "FETCH_RUBRICA_SUCCESS";
const FETCH_RUBRICA_FAIL = "FETCH_RUBRICA_FAIL";

const ADD_NAME_RUBRICA_START = "ADD_NAME_RUBRICA_START";
const ADD_NAME_RUBRICA_SUCCESS = "ADD_NAME_RUBRICA_SUCCESS";
const ADD_NAME_RUBRICA_FAIL = "ADD_NAME_RUBRICA_FAIL";

const DELETE_NAME_RUBRICA_START = "DELETE_NAME_RUBRICA_START";
const DELETE_NAME_RUBRICA_SUCCESS = "DELETE_NAME_RUBRICA_SUCCESS";
const DELETE_NAME_RUBRICA_FAIL = "DELETE_NAME_RUBRICA_FAIL";

const UPDATE_STORICO_CREDITI_START = "UPDATE_STORICO_CREDITI_START";
const UPDATE_STORICO_CREDITI_SUCCESS = "UPDATE_STORICO_CREDITI_SUCCESS";
const UPDATE_STORICO_CREDITI_FAIL = "UPDATE_STORICO_CREDITI_FAIL";

const UPDATE_ARRAY_RUBRICA = "UPDATE_ARRAY_RUBRICA";

export const fetchRubrica = () => {
  return async (dispatch) => {
    dispatch(fetchRubricaStart());
    try {
      const arrayRubrica = await searchRubrica();
      var lodash = require("lodash");
      const arrCrediti = arrayRubrica.map((item) => Number(item.credititoTotale));
      const totCrediti = lodash.sum(arrCrediti);
      dispatch(fetchRubricaSuccess(arrayRubrica, totCrediti));
    } catch (error) {
      dispatch(fetchRubricaFail(error));
    }
  };
};

export const fetchRubricaStart = () => {
  return {
    type: FETCH_RUBRICA_START,
  };
};

export const fetchRubricaSuccess = (arrayRubrica, totCrediti) => {
  return {
    type: FETCH_RUBRICA_SUCCESS,
    arrayRubrica,
    totCrediti,
  };
};

export const fetchRubricaFail = (error) => {
  return {
    type: FETCH_RUBRICA_FAIL,
    error: error,
  };
};

export const addNameRubrica = (cognome, nome, credititoTotale) => {
  return async (dispatch, getState) => {
    dispatch(addNameRubricaStart());
    //logic function
    credititoTotale = Number(credititoTotale);

    nome = String(nome).toLowerCase();
    cognome = String(cognome).toLowerCase();
    let storicoCrediti = ["false"];
    const alias = cognome + "-" + nome;

    // riferimento univco del magazzino Utente
    const email = localStorage.getItem("email");
    const refMag = email.split("@");
    const addRefRubrica = doc(db, `${refMag[0]}.magazzino`, "rubrica"); // riferimento del db nello rubrica
    const addRefNomi = doc(addRefRubrica, "rubrica", alias); // rif biglietti non ancora venduti

    // creo il riferimento per la rubrica
    let dataRubrica = await getDoc(addRefNomi);
    const rubricaExist = dataRubrica.exists();
    if (rubricaExist === false) {
      //creo un nuovo documento nella rubrica che sarebbe il primo
      setDoc(addRefNomi, { cognome });
      updateDoc(addRefNomi, { nome });
      updateDoc(addRefNomi, { credititoTotale });
      updateDoc(addRefNomi, { storicoCrediti });
      updateDoc(addRefNomi, { alias });
      dispatch(addNameRubricaSuccess());
      dispatch(fetchRubrica());
    } else {
      dispatch(addNameRubricaFail(true, "nome gia esistente"));
    }
  };
};

export const addNameRubricaStart = () => {
  return {
    type: ADD_NAME_RUBRICA_START,
  };
};

export const addNameRubricaSuccess = () => {
  return {
    type: ADD_NAME_RUBRICA_SUCCESS,

    //action
  };
};

export const addNameRubricaFail = (error, message) => {
  return {
    type: ADD_NAME_RUBRICA_FAIL,
    error: error,
    message: message,
  };
};

export const deleteNameRubrica = (alias) => {
  return async (dispatch, getState) => {
    dispatch(deleteNameRubricaStart());

    // riferimento univco del magazzino Utente
    const email = localStorage.getItem("email");
    const refMag = email.split("@");
    const addRefRubrica = doc(db, `${refMag[0]}.magazzino`, "rubrica"); // riferimento del db nello rubrica
    const addRefNomi = doc(addRefRubrica, "rubrica", alias); // rif biglietti non ancora venduti

    // creo il riferimento per la rubrica
    let dataRubrica = await getDoc(addRefNomi);
    const rubricaExist = dataRubrica.exists();
    if (rubricaExist === true) {
      //creo un nuovo documento nella rubrica che sarebbe il primo
      deleteDoc(addRefNomi);
      dispatch(deleteNameRubricaSuccess());
      dispatch(fetchRubrica());
    } else {
      dispatch(deleteNameRubricaFail(true, "ERORRE INASPETTATO RIPROVA"));
    }
  };
};

export const deleteNameRubricaStart = () => {
  return {
    type: DELETE_NAME_RUBRICA_START,
  };
};

export const deleteNameRubricaSuccess = () => {
  return {
    type: DELETE_NAME_RUBRICA_SUCCESS,

    //action
  };
};

export const deleteNameRubricaFail = (error, message) => {
  return {
    type: DELETE_NAME_RUBRICA_FAIL,
    error: error,
    message: message,
  };
};

export const updateStoricoCrediti = (storicoCrediti) => {
  let newArrayST = [];
  return async (dispatch, getState) => {
    dispatch(updateStoricoCreditiStart());
    try {
      storicoCrediti.forEach((element) => {
        let arrSplit = element.split("/");
        newArrayST.push({
          data: arrSplit[0],
          ora: arrSplit[1],
          importo: arrSplit[2],
        });
      });
      storicoCrediti = newArrayST;

      dispatch(updateStoricoCreditiSuccess(storicoCrediti));
    } catch (error) {
      dispatch(updateStoricoCreditiFail(true, "ERORRE INASPETTATO RIPROVA"));
    }
  };
};

export const updateStoricoCreditiStart = () => {
  return {
    type: UPDATE_STORICO_CREDITI_START,
  };
};

export const updateStoricoCreditiSuccess = (storicoCrediti) => {
  return {
    type: UPDATE_STORICO_CREDITI_SUCCESS,
    storicoCrediti,

    //action
  };
};

export const updateStoricoCreditiFail = (error, message) => {
  return {
    type: UPDATE_STORICO_CREDITI_FAIL,
    error: error,
    message: message,
  };
};

export const updateArrayRubrica = (arrayRubrica) => {
  return {
    type: UPDATE_ARRAY_RUBRICA,
    arrayRubrica,
  };
};

export {
  FETCH_RUBRICA_START,
  FETCH_RUBRICA_SUCCESS,
  FETCH_RUBRICA_FAIL,
  ADD_NAME_RUBRICA_START,
  ADD_NAME_RUBRICA_SUCCESS,
  ADD_NAME_RUBRICA_FAIL,
  DELETE_NAME_RUBRICA_START,
  DELETE_NAME_RUBRICA_SUCCESS,
  DELETE_NAME_RUBRICA_FAIL,
  UPDATE_STORICO_CREDITI_START,
  UPDATE_STORICO_CREDITI_SUCCESS,
  UPDATE_STORICO_CREDITI_FAIL,
  UPDATE_ARRAY_RUBRICA,
};
