import { combineReducers } from "redux";

import homeReducers from "./homeReducer";
import authReducer from "./authReducer";
import listaCreditiReducer from "./listaCreditiReducer";
import reportReducer from "./reportReducer";
import dataReducer from "./dataReducer";
import voiceSettingReducer from "./voiceSettingReducer";

const rootReducer = combineReducers({
  homeReducers,
  authReducer,
  listaCreditiReducer,
  reportReducer,
  dataReducer,
  voiceSettingReducer,
});

export default rootReducer;
