export const compareArrayVociCassa = async (array1, array2) => {
  const array3 = [];
  const array4 = [];
  await array2.forEach((obj) => {
    if (array1.some((v) => v.alias === obj.alias)) {
      array3.push(obj);
    } else {
      array4.push(obj);
    }
  });
  return [array3, array4];
};
