import React from 'react'
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import './IcoDelete.css'
import ReactTooltip from 'react-tooltip';

function IcoDelete({handelConfirmDelete}) {
  return (
    <div className="delete-ico-container"
         data-tip={`Premi per eliminare <br /> questo elemento`}
         data-delay-show="200">
         <p className="delete-ico">
        <MdIcons.MdRemove onClick={handelConfirmDelete}  />
      </p>
      {/* <MdIcons.MdOutlineDeleteSweep className="voce-ico-d" onClick={handelDelete} /> */}
      <p className="delete-ico-x">
        <BsIcons.BsCircleFill onClick={handelConfirmDelete} />
      </p>
      <ReactTooltip multiline={true} />
    </div>
    
  )
}

export default IcoDelete