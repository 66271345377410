export const StyleColorObject = (value) => {
  let colorBackground = "";
  let colorNumber = "";
  if (value > 0) {
    // colorBackground = "#ccffcc";
    colorNumber = "#008000";
  } else if (value < 0) {
    // colorBackground = "#ffcccc";
    colorNumber = "#FF0000";
  } else if (value === 0) {
    // colorBackground = "#fff";
    colorNumber = "2b3041";
  }
  return {
    backgroundColor: colorBackground,
    color: colorNumber,
    // borderColor: colorNumber,
  };
};
