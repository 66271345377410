import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "@firebase/firestore";

import db from "../../Firebase-config";
import { searchVociCassa } from "../../functions/searchVociCassa";
const FETCH_VOICE_START = "FETCH_VOICE_START";
const FETCH_VOICE_SUCCESS = "FETCH_VOICE_SUCCESS";
const FETCH_VOICE_FAIL = "FETCH_VOICE_FAIL";

const VOICE_SAVE_START = "VOICE_SAVE_START";
const VOICE_SAVE_SUCCESS = "VOICE_SAVE_SUCCESS";
const VOICE_SAVE_FAIL = "VOICE_SAVE_FAIL";

const VOICE_DELETE_START = "VOICE_DELETE_START";
const VOICE_DELETE_SUCCESS = "VOICE_DELETE_SUCCESS";
const VOICE_DELETE_FAIL = "VOICE_DELETE_FAIL";

const VOICE_ADD_START = "VOICE_ADD_START";
const VOICE_ADD_SUCCESS = "VOICE_ADD_SUCCESS";
const VOICE_ADD_FAIL = "VOICE_ADD_FAIL";

export const fetchVoice = () => {
  return async (dispatch) => {
    dispatch(fetchVoiceStart());
    try {
      const arrVociCassa = await searchVociCassa();
      dispatch(fetchVoiceSuccess(arrVociCassa));
    } catch (error) {
      dispatch(fetchVoiceFail(error));
    }
  };
};

export const fetchVoiceStart = () => {
  return {
    type: FETCH_VOICE_START,
  };
};

export const fetchVoiceSuccess = (arrVociCassa) => {
  return {
    type: FETCH_VOICE_SUCCESS,
    arrVociCassa,
  };
};

export const fetchVoiceFail = (error) => {
  return {
    type: FETCH_VOICE_FAIL,
    error: error,
  };
};

export const voiceSave = (voce, nuovoNome, nuovaPG) => {
  return async (dispatch) => {
    dispatch(voiceSaveStart());
    try {
      // riferimento univco del magazzino Utente
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello caledario
      const addRefVoceCassa = doc(addRefCalendario, "lista-voci-cassa", voce.alias); // rif voce cassa della lista generica
      let dataVoceCassa = await getDoc(addRefVoceCassa);

      if (!dataVoceCassa.exists()) {
        dispatch(voiceSaveFail("la voce non esiste"));
        return;
      }

      await updateDoc(addRefVoceCassa, {
        nome: nuovoNome,
        percentualeQuadagno: Number(nuovaPG),
        alias: voce.alias,
      });

      dispatch(voiceSaveSuccess());
      dispatch(fetchVoice());
    } catch (error) {
      dispatch(voiceSaveFail(error));
    }
  };
};

export const voiceSaveStart = () => {
  return {
    type: VOICE_SAVE_START,
  };
};

export const voiceSaveSuccess = () => {
  return {
    type: VOICE_SAVE_SUCCESS,
  };
};

export const voiceSaveFail = (error) => {
  return {
    type: VOICE_SAVE_FAIL,
    error: error,
  };
};
export const voiceAdd = (alias, nuovoNome, nuovaPG) => {
  return async (dispatch) => {
    dispatch(voiceAddStart());
    try {
      // riferimento univco del magazzino Utente
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello caledario
      const addRefVoceCassa = doc(addRefCalendario, "lista-voci-cassa", alias); // rif voce cassa della lista generica
      let dataVoceCassa = await getDoc(addRefVoceCassa);

      if (dataVoceCassa.exists()) {
        dispatch(voiceAddFail("la voce è gia esistente"));
        return;
      }

      await setDoc(addRefVoceCassa, {
        nome: nuovoNome,
        percentualeQuadagno: Number(nuovaPG),
        alias: alias,
      });

      dispatch(voiceAddSuccess());
      dispatch(fetchVoice());
    } catch (error) {
      dispatch(voiceAddFail(error));
    }
  };
};

export const voiceAddStart = () => {
  return {
    type: VOICE_ADD_START,
  };
};

export const voiceAddSuccess = () => {
  return {
    type: VOICE_ADD_SUCCESS,
  };
};

export const voiceAddFail = (error) => {
  return {
    type: VOICE_ADD_FAIL,
    error: error,
  };
};
export const voiceDelete = (voce) => {
  return async (dispatch) => {
    dispatch(voiceDeleteStart());
    try {
      // riferimento univco del magazzino Utente
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello caledario
      const addRefVoceCassa = doc(addRefCalendario, "lista-voci-cassa", voce.alias); // rif voce cassa della lista generica
      let dataVoceCassa = await getDoc(addRefVoceCassa);

      if (!dataVoceCassa.exists()) {
        dispatch(voiceDeleteFail("la voce non esiste"));
        return;
      }

      await deleteDoc(addRefVoceCassa);
      dispatch(voiceDeleteSuccess());
      dispatch(fetchVoice());
    } catch (error) {
      dispatch(voiceDeleteFail(error));
    }
  };
};

export const voiceDeleteStart = () => {
  return {
    type: VOICE_DELETE_START,
  };
};

export const voiceDeleteSuccess = () => {
  return {
    type: VOICE_DELETE_SUCCESS,
  };
};

export const voiceDeleteFail = (error) => {
  return {
    type: VOICE_DELETE_FAIL,
    error: error,
  };
};

export {
  FETCH_VOICE_START,
  FETCH_VOICE_SUCCESS,
  FETCH_VOICE_FAIL,
  VOICE_DELETE_FAIL,
  VOICE_DELETE_START,
  VOICE_DELETE_SUCCESS,
  VOICE_SAVE_START,
  VOICE_SAVE_SUCCESS,
  VOICE_SAVE_FAIL,
  VOICE_ADD_START,
  VOICE_ADD_SUCCESS,
  VOICE_ADD_FAIL,
};
