import { doc, collection, getDocs, query } from "@firebase/firestore";
import db from "../Firebase-config";
import { searchVociCassa } from "./searchVociCassa";

export const searchVociCassaSingleDay = async (day) => {
  let arrayVociCassa = [];
  // riferimento univco del magazzino Utente
  const email = localStorage.getItem("email");
  const refMag = email.split("@");
  const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello rubrica
  const addReDay = doc(addRefCalendario, "giorni", day); // rif caledario giorno specifo

  const q = query(collection(addReDay, "voci-cassa"));
  const querySnapshot = await getDocs(q);
  const position = querySnapshot.docs;
  position.forEach(async (docV) => {
    arrayVociCassa.push({
      nome: docV._document.data.value.mapValue.fields.nome.stringValue,
      alias: docV._document.data.value.mapValue.fields.alias.stringValue,
      percentualeQuadagno:
        docV._document.data.value.mapValue.fields.percentualeQuadagno.integerValue,
      valore: docV._document.data.value.mapValue.fields.valore.doubleValue
        ? docV._document.data.value.mapValue.fields.valore.doubleValue
        : docV._document.data.value.mapValue.fields.valore.integerValue,
    });
  });

  const listaVoci = await searchVociCassa();
  listaVoci.forEach((element) => {
    arrayVociCassa.forEach((vc) => {
      if (element.alias === vc.alias) {
        vc.percentualeQuadagno = element.percentualeQuadagno;
      }
    });
  });
  return arrayVociCassa;
};
