import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { voiceDelete, voiceSave } from "../../store/actions/handelVoiceSetting";
import IcoDelete from "../globalComponent/IcoDelete";
import "./VoiceCardSetting.css";

function VoiceCardSetting({ voice }) {
  const dispatch = useDispatch();
  const [nome, setNome] = useState(voice.nome);
  const [percentualeQuadagno, setPercentualeQuadagno] = useState(
    voice.percentualeQuadagno
  );

  useEffect(() => {
    setNome(voice.nome);
    setPercentualeQuadagno(voice.percentualeQuadagno);
  }, [voice.nome, voice.percentualeQuadagno]);

  const handleInputName = (e) => {
    setNome(e.target.value);
    e.preventDefault();
  };
  const handleInputPG = (e) => {
    setPercentualeQuadagno(e.target.value);
    e.preventDefault();
  };

  const handelConfirmDelete = () => {
    Swal.fire({
      title: `Sei sicuro di voler eliminare questa voce ??`,
      text: ` "${nome.toUpperCase()}" verra eliminata dalla lista delle voci`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00D100",
      cancelButtonColor: "#d33",
      confirmButtonText: "SI, ELIMINA !!",
      cancelButtonText: "NO",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(voiceDelete(voice));
      }
    });
  };
  const handleSave = () => {
    dispatch(voiceSave(voice, nome, percentualeQuadagno));
  };
  return (
    <div className="voice-card-container">
      <div className="ico-delete-container">
        <IcoDelete handelConfirmDelete={handelConfirmDelete} />
      </div>

      <div className="input-group">
        <input type="text" value={nome} onChange={handleInputName} />
      </div>
      <div className="text-group">nome della voce</div>

      <div className="input-group">
        <input type="number" value={percentualeQuadagno} onChange={handleInputPG} />
      </div>
      <div className="text-group">percentuale di guadagno %</div>
      <div className="btn-save-container">
        <button onClick={handleSave}>SALVA</button>
      </div>
    </div>
  );
}

export default VoiceCardSetting;
