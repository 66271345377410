import React from "react";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import * as ImIcons from "react-icons/im";

export const data = {
  title: "FAQ (Domande Frequenti)",
  rows: [
    {
      title: "Suggerimento, Esempio Voci Cassa",
      content: (
        <p>
          1. Esempio voci cassa Tabacchi <br />
          <br />
          • Distributore Presi – 6%
          <br />
          • Distributore Messi - 0%
          <br />
          • Lotto entrate – 8%
          <br />
          • Lotto Uscite - 0%
          <br />
          • Pos - 0%
          <br />
          • Sigarette – 6%
          <br />
          • Sisal Gioco entrate – 8%
          <br />
          • Sisal Gioco Uscite – 0%
          <br />
          • Terzia – 30%
          <br />
          • Vendita Generica – 30%
          <br />
          • Gratta&Vinci Venduti – 8%
          <br />
          • Marche da bollo – 8%
          <br />
          • Tot Cassa Servizi Sir – 0%
          <br />
          • Aggio Servizi Sir – 100%
          <br />
          • Aggio Lottomatica Servizi – 100%
          <br />
          <br /> *Le Percentuali sono al netto della tassazione media di una tabaccheria.
          <br />
          <br />
          <br />
          <br />
          2. Esempio voci cassa Centro Scommesse <br />
          <br />
          • Commissioni sito1 – 100% <br />
          • Commissioni sito2 – 100% <br />
          • Utile Giornaliero sito1 – 0% <br />
          • Utile Giornaliero sito2 – 0% <br />
          • Bar – 30% <br />
          • Pos – 0% <br />
          • Biliardo – 100% <br />
          <br />
          *Le Percentuali sono al netto della tassazione media di un centro scommesse
        </p>
      ),
    },
    {
      title: "Come aggiungo un nuovo Credito alla rubrica ?",
      content: (
        <p>
          Aggiungere un nuovo credito è davvero semplicissimo reacti nella tua Home e
          premi il pulsante + nella colonna 'totale crediti' seleziona il nome dal menu a
          tendida oppure scrivilo manualmente ed inserisci l'importo del credito, se il
          nome non è presente nel menu a tendina allora devi prima inserirlo nella Rubrica
        </p>
      ),
    },
    {
      title: "Come si aggiunge un nome alla rubrica ?",
      content: (
        <p>
          Hai due opzioni : <br />
          1- Se vuoi aggiungere un Creditore che ha gia un credito esistente che vuoi
          riportare su questo sofware allora devi recarti nel menu laterale alla voce
          'Lista Crediti' <BsIcons.BsPersonLinesFill /> preme il tasto{" "}
          <ImIcons.ImUserPlus /> ed inserisci tutti i dati richeisti <br />
          2- Vuoi aggiungere Creditore nuovo che non ha un credito già esistente , pupi
          usare la procedura del punto 1 e mettere come importo del credito 0 oppure
          andare nella Home <AiIcons.AiFillHome /> e sempre nella dei crediti preere il
          tasto <ImIcons.ImUserPlus /> inserire cognome e nome ed il Creditore verra
          aggiunto alla rubrica.
        </p>
      ),
    },
    {
      title: "Cosa sono i Movimenti Cassa ?",
      content: (
        <p>
          I Movimenti Cassa sono tutti quei movimenti che non rientrano fra i crediti e le
          spese di seguito qualche Esempio : <br />
          Soldi presi della cassa da un gestore del punto vendita <br />
          Soldi messi nella cassa perchè perchè c'erano pochi soldi per tornare il resto{" "}
          <br />
          Soldi presi a fine serata o durante il giorno perchè erano troppi o per levarli
          dalla cassa <br />
        </p>
      ),
    },
    {
      title: "Cosa sono le Spese ??",
      content: (
        <p>
          Rietrano nella categrie delle spese tutti quei movimenti che vengono fatti per
          pagare un fornitore o un servizo , Esempio: <br />
          Fornitore Bevante <br />
          Fornitore Patatine <br />
          Fornitore Cioccolati <br />
          Abbonamento TV <br />
          Canone rai <br />
          Sky <br />
          DANZ <br />
          Affitto <br />
          Stipendi <br />
        </p>
      ),
    },
    {
      title: "Come aggiungo un nuovo Movimento Cassa ?",
      content: (
        <p>
          Aggiungere un nuovo Mov Cassa è davvero semplicissimo recati nella tua Home,
          premi il pulsante + nella colonna 'Mov Cassa' ed inserisci l'improto e la
          causale del movimento <br />
          es: <br />
          importo = -100 <br />
          causale = soldi presi da Mario Rossi
        </p>
      ),
    },
    {
      title: "Come elimino un Movimento Cassa ?",
      content: <p>Vai sul movimento e premi il bollino rosso</p>,
    },
    {
      title: "Come aggiungo un nuova Spesa ?",
      content: (
        <p>
          Aggiungere una nuova Spesa è davvero semplicissimo recati nella tua Home, premi
          il pulsante + nella colonna 'Spese' ed inserisci l'improto e la causale della
          Spesa <br />
          es: <br />
          importo = -100 <br />
          causale = Fornitore Bevande
        </p>
      ),
    },
    {
      title: "Come elimino una Spesa ?",
      content: <p>Vai sulla spsa e premi il bollino rosso</p>,
    },

    {
      title: "Cosa sono le Voci Cassa ?",
      content: (
        <p>
          Le Voci Cassa rappresentano tutti i servizi che hai nel tuo punto vendita per i
          queli hai la necessita di contabillizare ad esempio per un tabacchi potrebbero
          essere: <br />
          Lottomatica <br />
          Sigarette <br />
          Sisal <br />
          Distributore <br />
          Terzia <br />
          Bevande <br />
          Genereico <br />
          Pos <br />
          Se per dire stai utilizzando FlowMoney in un centro scommesse potrebbero essere:
          <br />
          Sito1 <br />
          Sito2 <br />
          Sito3 <br />
          Bar <br />
          Biliardo <br />
          Pos <br />
        </p>
      ),
    },
    {
      title: "Come si aggiunge una nuova Voce Cassa ?",
      content: (
        <p>
          Hai due opzioni : <br />
          1- Andare nella Home, nella colonna delle voci cassa premere il tasto + e dopo
          di che inserire tutti i dati richiesti. <br />
          2- Andare nel nel menu laterale su Impostazioni Voci{" "}
          <RiIcons.RiChatSettingsFill /> premere il tasto + ed insierie tutti i dati
          richiesti. Nota bene : La percentuale di guadagno va inserita solo se si ha
          coscienza di quale sia relmente altrimenti va inserito 0
        </p>
      ),
    },
    {
      title: "Posso modificare la percentuale di guadagno che ho inseirto in una voce ?",
      content: (
        <p>
          Certo niente di più semplice !!! Vai nel nel menu laterale su Impostazioni Voci{" "}
          <RiIcons.RiChatSettingsFill /> cerca fra le voci esistenti quella che vuoi
          modifcare e potrai modifcare sia il nome che la percentuale di guadagno o
          addirittura eliminarla
        </p>
      ),
    },
    {
      title: "Posso modificare il nome che ho inseirto in una voce ?",
      content: (
        <p>
          Certo niente di più semplice !!! Vai nel nel menu laterale su Impostazioni Voci{" "}
          <RiIcons.RiChatSettingsFill /> cerca fra le voci esistenti quella che vuoi
          modifcare e potrai modifcare sia il nome che la percentuale di guadagno o
          addirittura eliminarla
        </p>
      ),
    },
    {
      title: "Posso eliminare una voce cassa ?",
      content: (
        <p>
          Certo niente di più semplice !!! Vai nel nel menux laterale su Impostazioni Voci{" "}
          <RiIcons.RiChatSettingsFill /> cerca fra le voci esistenti quella che vuoi
          elinare e potrai modifcare sia il nome che la percentuale di guadagno o
          eliminarla
        </p>
      ),
    },
    {
      title: "Non so a cosa serve un tasto cosa devo fare ?",
      content: (
        <p>
          Tutti tasti sono molto intuitivi ma se ci dovessere essere qualche tasto del
          quale non si e sicuro del funzionamento basta avvicinari con il mouse e come per
          magia apparira un suggerimento con la precisa funzione di quel tasto
        </p>
      ),
    },
    {
      title: "Ho bisgno di assistenza cosa devo fare ?",
      content: (
        <p>
          Apri un tiket nella apposita sezione <MdIcons.MdOutlineLiveHelp /> e verrai
          subito contattato da un nostro consulente che si metterà a tua completa
          disposizone per risolvere ogni tuo problema
        </p>
      ),
    },
  ],
};
