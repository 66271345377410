import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "../styles/Home.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addName,
  addCredito,
  fetchDayTotal,
  errorReset,
  addMovCassa,
  addVoceCassa,
  addSpesa,
  deleteSpesa,
  deleteMovCassa,
} from "../store/actions/handleHome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { stateChange } from "../functions/stateChange";
import { checkMessage } from "../functions/Message";
import { CeckConnection } from "../functions/ConnectionStatius";
import { searchRubrica } from "../functions/searchRubrica";
import soudError from "../sound/error.wav";
import VoceCassaCard from "../components/homeComponent/VoceCassaCard";
import SignleCreditCard from "../components/homeComponent/SignleCreditCard";
import SingleMovCassa from "../components/homeComponent/SingleMovCassa";
import { StyleColorObject } from "../functions/StyleColorObject";
import ColumService from "../components/homeComponent/ColumService";
import ColumServiceCredit from "../components/homeComponent/ColumServiceCerdit";
import ColumServiceVociCassa from "../components/homeComponent/ColumServiceVociCassa";
import DataPikers from "../components/globalComponent/DataPikers";
import Navbar from "../components/Navbar";
import "../styles/Price.scss";
import SweetAlert2 from "react-sweetalert2";
import ReactAutocomplete from "react-autocomplete";
import Autocomplete from "@mui/joy/Autocomplete";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AddCardIcon from "@mui/icons-material/AddCard";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

registerLocale("it", it);
function Home() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  // const email = localStorage.getItem("email");
  const userToken = useSelector((state) => state.authReducer.token);
  const startDate = useSelector((state) => state.dataReducer.startDate);
  const [swalProps, setSwalProps] = useState({});
  const [value, setValue] = useState({});
  const [items, setItems] = useState([]);
  const [price, setPrice] = useState("");

  const {
    error,
    message,
    loadingFetch,
    loadingCredit,
    loadingMovCassa,
    loadingSpese,
    saveVociCassa,
    totaleCassa,
    creditiGiorno,
    movimentiCassa,
    arrayVociCassa,
    aggioGiornaliero,
    totaleCreditiGiorno,
    totaleSpese,
    arraySpese,
    totaleMovimentiCassa,
  } = useSelector((state) => state.homeReducers);

  // styles
  const stylesTCG = StyleColorObject(totaleCreditiGiorno);
  const stylesTMC = StyleColorObject(totaleMovimentiCassa);
  const stylesTC = StyleColorObject(totaleCassa);
  const stylesAG = StyleColorObject(aggioGiornaliero);
  const stylesTS = StyleColorObject(totaleSpese);
  // ----------------------------------------------------------------

  // audio errore
  const audio = new Audio(soudError);
  audio.loop = false;
  const playError = () => {
    audio.loop = false;
    audio.play();
  };
  // ----------------------------------------------------------------

  // tost message errore
  const notifyError = () =>
    toast.error(message, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  // -----------------------------
  // tost message errore
  const notifySuccess = () =>
    toast.success("Salvato con sucesso !!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  // -----------------------------

  // // */1 * * * * evrey  five minutes
  // // 0 10 * * *  every day at 10 am
  // var controlSubscription = new CronJob("0 10 * * *", async function () {
  //   // control if subscrition is active or trialing
  //   // if is not acrive or trialing dispatch login functions
  //   const resStaSubscription = await statusCheck(email);
  //   // console.log(resStaSubscription);
  //   if (!resStaSubscription.status) {
  //     dispatch(logoutRoot());
  //     window.location.reload();
  //     return;
  //   }
  //   // ------
  // });
  // controlSubscription.start();

  useEffect(() => {
    // verifica connessione
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }

    //______
    if (token) {
      stateChange();
      dispatch(errorReset());
      handleMessage();
    }
  }, [
    dispatch,
    startDate,
    token,
    totaleCassa,
    totaleCreditiGiorno,
    totaleMovimentiCassa,
    totaleSpese,
    arrayVociCassa,
    aggioGiornaliero,
    // fetchAggioDay,
  ]);

  // se non è presente il token allora il cliente  non è registrato oppure non è loggato
  if (!token) {
    return <Redirect to="/auth"></Redirect>;
  }

  // controlla se ci sono messaggi da far apparire a tutti gli utenti dopo  essersi loggati
  const handleMessage = async () => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }

    //-----
    const res = await checkMessage();
    if (res.display === true) {
      Swal.fire({
        title: res.title,
        icon: "info",
        html: res.message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    }
  };

  // resetta errori
  const alterShow = () => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }
    //-----
    dispatch(errorReset());
  };

  const handleAddName = async () => {
    const { value: formValues } = await Swal.fire({
      title: "NUOVO NOME",
      html:
        '<input id="swal-input1" class="swal2-input" placeholder="cognome">' +
        '<input id="swal-input2" class="swal2-input" placeholder="nome">',

      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value.trim(),
          document.getElementById("swal-input2").value.trim(),
        ];
      },
    });
    if (formValues) {
      if (!formValues[0]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Il campo del COGNOME è vuoto",
        });
        return;
      }
      if (!formValues[1]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Il campo del NOME è vuoto",
        });
        return;
      }
      dispatch(addName(formValues[0].trim(), formValues[1].trim()));
    }
  };
  /*
  const handleAddCredit = async () => {
    const arrayRubrica = await searchRubrica();
    let options = '<option value="vuoto">Eelenco Nomi</option>"';
    arrayRubrica.forEach((element) => {
      var optionTag =
        '<option value="' +
        element.cognome +
        "-" +
        element.nome +
        '">' +
        element.cognome.toUpperCase() +
        " " +
        element.nome.toUpperCase() +
        "</option>";
      options = options.concat(optionTag);
    });

    const { value: formValues } = await Swal.fire({
      title: "Nuovo Credito",
      html:
        `<p class="swal2-header">Seleziona un nome</p>` +
        '<select class="swal2-input edited" id="drpUserInput">' +
        options +
        "</select>" +
        `<p class="swal2-header">oppure inseriscilo manualmente</p>` +
        '<input id="swal-input1" class="swal2-input" placeholder="cognome">' +
        '<input id="swal-input2" class="swal2-input" placeholder="nome">' +
        `<p class="swal2-header">inserisci l'importo del credito</p>` +
        '<input id="swal-input3" class="swal2-input" type="number" required placeholder="importo">',

      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value.trim(),
          document.getElementById("swal-input2").value.trim(),
          document.getElementById("swal-input3").value,
          document.getElementById("drpUserInput").value,
        ];
      },
    });

    if (formValues) {
      let nome;
      let cognome;
      let importo;
      if (!formValues[0] && !formValues[1] && formValues[2] && formValues[3]) {
        // il nome è stato inserito tramite elenco quindi
        // formValues[3] = nome-cognome
        // formValues[2] = importo ---> mi devo accertare che l'importo sia in un formato corretto
        let cognomeNome = formValues[3].split("-");
        cognome = cognomeNome[0];
        nome = cognomeNome[1];
        importo = Number(formValues[2]);
        dispatch(addCredito(cognome, nome, importo, startDate));
      } else if (
        formValues[0] &&
        formValues[1] &&
        formValues[3] === "vuoto" &&
        formValues[2]
      ) {
        // il nome è stato inserito manualmente
        // formValues[0] = cognome
        // formValues[1] = nome
        // formValues[2] = importo ---> mi devo accertare che l'importo sia in un formato corretto
        cognome = formValues[0];
        nome = formValues[1];
        importo = Number(formValues[2]);
        dispatch(addCredito(cognome, nome, importo, startDate));
      } else {
        // campo cognome è vuoto
        if (!formValues[0]) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Il campo COGNOME è vuoto , inseriscilo oppure scegli il nome dal menu a tendina",
          });
          return;
        }
        // campo cognome è vuoto
        if (!formValues[1]) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Il campo NOME è vuoto , inseriscilo oppure scegli il nome dal menu a tendina",
          });
          return;
        }
        if (
          (formValues[0] && formValues[1] && formValues[3] !== "vuoto") ||
          (formValues[0] && formValues[3] !== "vuoto") ||
          (formValues[1] && formValues[3] !== "vuoto")
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Puoi inserire il nome o dall'elenco a discesa oppure manualmente non puoi usare entrambi, se stai usando il menu a tendina allora lascia i campi 'nome' e 'cognome vuoti'",
          });
          return;
        }
        if (!formValues[2]) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Il campo del credito è vuoto, inserisci l'importo del credito",
          });
          return;
        }
      }
    }
  };
*/
  const handleAddCredit = async () => {
    const arrayRubrica = await searchRubrica();
    const listContact = [];
    arrayRubrica.forEach((element) => {
      listContact.push({
        id: element.cognome + "-" + element.nome,
        label: element.cognome.toUpperCase() + " " + element.nome.toUpperCase(),
        name: element.nome,
        surname: element.cognome,
      });
    });
    setItems(listContact);

    setSwalProps({
      show: true,
      title: "Nuovo Credito",
      focusConfirm: false,
      showCancelButton: true,
    });
  };

  const handleAddMovCassa = async () => {
    const { value: formValues2 } = await Swal.fire({
      title: "Nuovo Movimento Cassa",
      html:
        `<p class="swal2-header">Inserisci l'importo del movimento</p>` +
        '<input id="swal-input1" class="swal2-input" type="number" placeholder="es. -100 o  + 100">' +
        ` <p class="swal2-header">inserisci la cusale del movimento </p>` +
        '<input id="swal-input2" class="swal2-input" type="textarea" placeholder="es. soldi presi dalla cassa">',

      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value.trim(),
        ];
      },
    });

    if (formValues2) {
      if (!formValues2[0]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "non hai inserito l'importo, devi obbligatoriamente inserire un importo",
        });
        return;
      }
      if (!formValues2[1]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "non hai inserito una causale, devi obbligatoriamente inserire una causale",
        });
        return;
      }
      if (formValues2[0] && formValues2[1]) {
        dispatch(addMovCassa(formValues2[0], formValues2[1], startDate));
      }
    }
  };

  const handleAddSpesa = async () => {
    const { value: formValues3 } = await Swal.fire({
      title: "Nuova Spesa",
      html:
        `<p class="swal2-header">Inserisci l'importo della Spesa</p>` +
        '<input id="swal-input1" class="swal2-input" type="number" placeholder="es. -100 o  + 100">' +
        `<p class="swal2-header">inserisci la causale della spesa</p>` +
        '<input id="swal-input2" class="swal2-input" type="textarea" placeholder="es. Fornitore bevande">',

      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value.trim(),
        ];
      },
    });

    if (formValues3) {
      if (!formValues3[0]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "non hai inserito l'importo, devi obbligatoriamente inserire un importo",
        });
        return;
      }
      if (!formValues3[1]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "non hai inserito una causale, devi obbligatoriamente inserire una causale",
        });
        return;
      }
      if (formValues3[0] && formValues3[1]) {
        dispatch(addSpesa(formValues3[0], formValues3[1], startDate));
      }
    }
  };

  const handleAddVociCassa = async () => {
    const { value: formValues3 } = await Swal.fire({
      title: "Nuova Voce",
      html:
        '<p class="swal2-header">Inserisci il nome della voce</p>' +
        '<input id="swal-input1" class="swal2-input" type="text" placeholder="es. Distributore Presi ">' +
        // `<p class="swal2-header">inserisci l'alias della voce</p>` +
        // `<p class="swal2-header">l'alias deve essere minuscolo, senza spazi e senza caratteri speciali</p>` +
        // '<input id="swal-input2" class="swal2-input" type="textarea" placeholder="es. distributore-presi">' +
        `<p class="swal2-header"> se è quantificabile insiersci la percentuale di guadagno relativa a questa voce altrimenti inserisci '0'</p>` +
        '<input id="swal-input3" class="swal2-input" type="number" placeholder="es. 10 or 0 ">',

      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value.trim(),
          document.getElementById("swal-input3").value,
        ];
      },
    });

    if (formValues3) {
      if (!formValues3[1]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "non hai inserito la percentuale di guadagno, se non è quantificabile inserisci 0",
        });
        return;
      }
      if (formValues3[0] && formValues3[1]) {
        dispatch(
          addVoceCassa(
            formValues3[0].replaceAll(" ", "-").toLowerCase(),
            formValues3[0],
            Number(formValues3[1]),
            startDate
          )
        );
      }
    }
  };
  const renderSpinner = () => {
    return (
      <div className="local-bootstrap">
        <div className="spinner-center mt-5">
          <div
            class="spinner-border"
            style={{ width: "3rem", height: "3rem", color: "#4c79d8" }}
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  };

  const renderBodyCrediti = () => {
    if (!creditiGiorno.includes("false")) {
      return creditiGiorno.map((person) => {
        console.log(person);
        return (
          <>
            <SignleCreditCard person={person} />
          </>
        );
      });
    } else {
      return;
    }
  };

  const renderTableCrediti = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 480 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="right">C. Giorno</TableCell>
              <TableCell align="right">C. Totale</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creditiGiorno.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.nome + " " + row.cognome}
                </TableCell>
                <TableCell align="right">{row.totaleCG}</TableCell>
                <TableCell align="right">{row.credititoTotale}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const renderBodyMovCassa = () => {
    if (!movimentiCassa.includes("false")) {
      return movimentiCassa.map((mov) => {
        return (
          <>
            <SingleMovCassa mov={mov} day={startDate} deleteFunction={deleteMovCassa} />
          </>
        );
      });
    } else {
      return;
    }
  };
  const renderBodySpesa = () => {
    if (!arraySpese.includes("false")) {
      return arraySpese.map((mov) => {
        return (
          <>
            <SingleMovCassa mov={mov} day={startDate} deleteFunction={deleteSpesa} />
          </>
        );
      });
    } else {
      return;
    }
  };

  const renderBodyVociCassa = () => {
    return arrayVociCassa.map((vociCassa) => {
      return (
        <>
          <VoceCassaCard
            id={vociCassa.alias}
            nome={vociCassa.nome}
            valore={vociCassa.valore}
            percentualeQuadagno={vociCassa.percentualeQuadagno}
            day={startDate}
          />
        </>
      );
    });
  };
  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <div className="home">
        <DataPikers fetchFunctions={fetchDayTotal} />
        <ColumServiceCredit
          value={totaleCreditiGiorno}
          style={stylesTCG}
          addCreditFunction={handleAddCredit}
          addNameFunction={handleAddName}
          renderBodyFunction={
            loadingFetch || loadingCredit ? renderSpinner : renderBodyCrediti
          }
        />
        <ColumService
          titleHead={`Totale Mov Cassa`}
          ico={<BsIcons.BsArrowDownUp />}
          title={"Mov Cassa"}
          value={totaleMovimentiCassa}
          style={stylesTMC}
          addFunction={handleAddMovCassa}
          renderBodyFunction={
            loadingFetch || loadingMovCassa ? renderSpinner : renderBodyMovCassa
          }
          dataTip={`In questa colonna sono rappresentati <br /> tutti i movimenti fatti nella cassa`}
          dataTipButton={`Premi questo tasto se vuoi <br /> aggiungere un movimento`}
          icon={<ImportExportIcon sx={{ color: "#fff", fontSize: "50px" }} />}
        />
        <ColumService
          titleHead={`Totale Spese`}
          ico={<BsIcons.BsCashStack />}
          title={"Spese"}
          value={totaleSpese}
          style={stylesTS}
          addFunction={handleAddSpesa}
          renderBodyFunction={
            loadingFetch || loadingSpese ? renderSpinner : renderBodySpesa
          }
          dataTip={`In questa colonna sono rappresentate <br /> tutte le spese del giorno`}
          dataTipButton={`Premi questo tasto se vuoi <br /> aggiungere una spesa`}
          icon={<AddCardIcon sx={{ color: "#fff", fontSize: "50px" }} />}
        />
        <ColumServiceVociCassa
          value={totaleCassa}
          style={stylesTC}
          styleAG={stylesAG}
          addFunction={handleAddVociCassa}
          renderBodyFunction={loadingFetch ? renderSpinner : renderBodyVociCassa}
          aggioGiornaliero={aggioGiornaliero}
        />
        {error ? (playError(), notifyError(), alterShow()) : null}
        {saveVociCassa ? (notifySuccess(), alterShow(), dispatch(errorReset())) : null}
        <ToastContainer
          position="top-letf"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SweetAlert2
          {...swalProps}
          preConfirm={(res) => {
            // const name = document.getElementById("input-autocomplete").value;
            const importo = document.getElementById("input-price").value;
            // const nome = name.split(" - ")[1].trim().toLowerCase();
            // const cognome = name.split(" - ")[0].trim().toLowerCase();
            // console.log("nome", nome);
            // console.log("cognome", cognome);

            const val = JSON.parse(localStorage.getItem("value"));
            dispatch(addCredito(val.surname, val.name, Number(importo), startDate));
            setPrice("");
            setValue("");
          }}
          onConfirm={(res) => {
            setSwalProps({
              show: false,
            });
            setPrice("");
            setValue("");
          }}
          onResolve={(res) => {
            setSwalProps({
              show: false,
            });
            setPrice("");
            setValue("");
          }}
          didClose={(res) => {
            setSwalProps({
              show: false,
            });
            setPrice("");
            setValue("");
          }}
        >
          <p style={{ marginBottom: "0px", fontSize: "18px" }} class="swal2-header">
            Seleziona il nome del creditore dal menu a tendida
          </p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Autocomplete
              placeholder="Scegli il nome"
              options={items}
              sx={{ width: 250 }}
              menuStyle={{
                borderRadius: "3px",
                boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                background: "rgba(255, 255, 255, 0.9)",
                padding: "2px 0",
                fontSize: "90%",
                position: "fixed",
                overflow: "auto",
                maxHeight: "50%", // TODO: don't cheat, let it flow to the bottom
                zIndex: 100,
              }}
              getOptionLabel={(user) => user.label}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={(event, newValue) => {
                localStorage.setItem("value", JSON.stringify(newValue));
                setValue(newValue);
              }}
            />
          </div>
          <p style={{ fontSize: "18px", marginTop: "40px" }} class="swal2-header">
            inserisci l'importo del credito
          </p>
          <input
            id="input-price"
            class="swal2-input"
            type="number"
            required
            placeholder="importo"
            onInput={(e) => {
              setPrice(e.target.value);
            }}
            value={price}
            style={{ marginTop: "20px" }}
          />
        </SweetAlert2>
      </div>
    </>
  );
}

export default Home;
