import React from "react";
import { useSelector } from "react-redux";
import Navbar from "../components/Navbar";
import CreatePdf from "../components/reportComponent/CreatePdf";

const PdfViewer = () => {
  const userToken = useSelector((state) => state.authReducer.token);
  const {
    arrayReport,
    arrVociCassa,
    arrTotVociCassa,
    dataFineStatistiche,
    dataInizioStatistiche,
  } = useSelector((state) => state.reportReducer);

  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <div className="pdf-page">
        <CreatePdf
          arrayReport={arrayReport}
          arrVociCassa={arrVociCassa}
          arrTotVociCassa={arrTotVociCassa}
          dataInizioStatistiche={dataInizioStatistiche}
          dataFineStatistiche={dataFineStatistiche}
        />
      </div>
    </>
  );
};
export default PdfViewer;
