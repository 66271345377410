import {
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  getDocs,
  query,
} from "@firebase/firestore";
import { v4 as uuid } from "uuid";

import db from "../../Firebase-config";
import { convertMovCassaToArrayOfObject } from "../../functions/convertMovCassaToArrayOfObject.js";
import { convertToArrayOfObject } from "../../functions/convertToArrayOfObject";
import { ReplaceTotalToDuplicateName } from "../../functions/ReplaceTotalToDuplicateName";
import { updateTotal } from "../../functions/updateTotal";
import { searchVociCassa } from "../../functions/searchVociCassa";
import { searchVociCassaSingleDay } from "../../functions/searchVociCassaSingleDay";
import { compareArrayVociCassa } from "../../functions/compareArrayVociCassa";
import { RemoveArrayObjectoForID } from "../../functions/RemoveArrayObjectoForID";
import {DataTranform} from "../../functions/DateTranform";

const ADD_NAME_START = "ADD_NAME_START";
const ADD_NAME_SUCCESS = "ADD_NAME_SUCCESS";
const ADD_NAME_FAIL = "ADD_NAME_FAIL";

const FETCH_DAY_TOTAL_START = "FETCH_HOME_TOTAL_START";
const FETCH_DAY_TOTAL_SUCCESS = "FETCH_HOME_TOTAL_SUCCES";
const FETCH_DAY_TOTAL_FAIL = "FETCH_HOME_TOTAL_FAIL";

const ADD_CREDITO_START = "ADD_CREDITO_START";
const ADD_CREDITO_SUCCESS = "ADD_CREDITO_SUCCESS";
const ADD_CREDITO_FAIL = "ADD_CREDITO_FAIL";

const ADD_MOV_CASSA_START = "ADD_MOV_CASSA_START";
const ADD_MOV_CASSA_SUCCESS = "ADD_MOV_CASSA_SUCCESS";
const ADD_MOV_CASSA_FAIL = "ADD_MOV_CASSA_FAIL";

const DELETE_MOV_CASSA_START = "DELETE_MOV_CASSA_START";
const DELETE_MOV_CASSA_SUCCESS = "DELETE_MOV_CASSA_SUCCESS";
const DELETE_MOV_CASSA_FAIL = "DELETE_MOV_CASSA_FAIL";

const ADD_SPESA_START = "ADD_SPESA_START";
const ADD_SPESA_SUCCESS = "ADD_SPESA_SUCCESS";
const ADD_SPESA_FAIL = "ADD_SPESA_FAIL";

const DELETE_SPESA_START = "DELETE_SPESA_START";
const DELETE_SPESA_SUCCESS = "DELETE_SPESA_SUCCESS";
const DELETE_SPESA_FAIL = "DELETE_SPESA_FAIL";

const ADD_VOCE_CASSA_START = "ADD_VOCE_CASSA_START";
const ADD_VOCE_CASSA_SUCCESS = "ADD_VOCE_CASSA_SUCCESS";
const ADD_VOCE_CASSA_FAIL = "ADD_VOCE_CASSA_FAIL";

const ADD_VOCE_CASSA_SAVE_START = "ADD_VOCE_CASSA_SAVE_START";
const ADD_VOCE_CASSA_SAVE_SUCCESS = "ADD_VOCE_CASSA_SAVE_SUCCESS";
const ADD_VOCE_CASSA_SAVE_FAIL = "ADD_VOCE_CASSA_SAVE_FAIL";

const ADD_VOCE_CASSA_DELETE_START = "ADD_VOCE_CASSA_DELETE_START";
const ADD_VOCE_CASSA_DELETE_SUCCESS = "ADD_VOCE_CASSA_DELETE_SUCCESS";
const ADD_VOCE_CASSA_DELETE_FAIL = "ADD_VOCE_CASSA_DELETE_FAIL";

const ERROR_RESET = "ERROR_RESET";

export const addName = (cognome, nome) => {
  return async (dispatch, getState) => {
    dispatch(addNameStart());
    //logic function
    let credititoTotale = 0;
    let storicoCrediti = ["false"];
    nome = String(nome).toLowerCase();
    cognome = String(cognome).toLowerCase();
    const alias = cognome + "-" + nome;

    // riferimento univco del magazzino Utente
    const email = localStorage.getItem("email");
    const refMag = email.split("@");
    const addRefRubrica = doc(db, `${refMag[0]}.magazzino`, "rubrica"); // riferimento del db nello rubrica
    const addRefNomi = doc(addRefRubrica, "rubrica", alias); // rif biglietti non ancora venduti

    // creo il riferimento per la rubrica
    let dataRubrica = await getDoc(addRefNomi);
    const rubricaExist = dataRubrica.exists();
    if (rubricaExist === false) {
      //creo un nuovo documento nella rubrica che sarebbe il primo
      setDoc(addRefNomi, { cognome });
      updateDoc(addRefNomi, { nome });
      updateDoc(addRefNomi, { credititoTotale });
      updateDoc(addRefNomi, { storicoCrediti });
      updateDoc(addRefNomi, { alias });
      dispatch(addNameSuccess());
    } else {
      dispatch(addNameFail(true, "nome gia esistente"));
    }
  };
};

export const addNameStart = () => {
  return {
    type: ADD_NAME_START,
  };
};

export const addNameSuccess = () => {
  return {
    type: ADD_NAME_SUCCESS,

    //action
  };
};

export const addNameFail = (error, message) => {
  return {
    type: ADD_NAME_FAIL,
    error: error,
    message: message,
  };
};

export const fetchDayTotal = (day) => {
  console.log(day);
  return async (dispatch) => {
    dispatch(fetchDayTotalStart());
    try {
      // recipente giorno singolo
      let creditiGiorno = [];
      let totaleCassa = 0;
      let movimentiCassa = [];
      let arrayVociCassa = [];
      let listaVociCassa = [];
      let totaleMovimentiCassa = 0;
      let totaleCreditiGiorno = 0;
      let totaleSpese = 0;
      let arraySpese = [];
      let valore = 0;
      let aggioGiornaliero = 0;
      // riferimento univo del magazzino Utente
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      //------

      const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello caledario
      const addReDay = doc(addRefCalendario, "giorni", day); // rif caledario giorno specifo

      // cerco ed elimino la la data più vecchia di 90 gg per evitare che il db si appesantisca troppo
      for (let i = 0; i < 3; i++) {
        let dateArr = day.split("-");
        let dataTemp = new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`);

        let days = 86400000; //number of milliseconds in a day
        let dataT = new Date(dataTemp - (90 + i) * days);
        const addReDayDelete = doc(
          addRefCalendario,
          "giorni",
          DataTranform(dataT)
        );
        let DayDelete = await getDoc(addReDayDelete);
        const dDExist = DayDelete.exists();
        if (dDExist === true) {
          deleteDoc(addReDayDelete);
        }
      }

      // cerco se il recipeinte vociCassa è esistente e se non lo è ne creo uno
      const vociCassa = query(collection(addRefCalendario, "lista-voci-cassa"));
      const querySnapshot = await getDocs(vociCassa);

      if (querySnapshot.docs.length <= 0) {
      } else {
        arrayVociCassa = await searchVociCassa();
      }

      // verifico che esista gia un totale per quel giorno e se non esiste ne creo uno
      let data = await getDoc(addReDay);
      const dExist = data.exists();
      if (dExist === false) {
        creditiGiorno.push("false");
        movimentiCassa.push("false");
        arraySpese.push("false");
        setDoc(addReDay, { creditiGiorno });
        updateDoc(addReDay, { totaleCassa });
        updateDoc(addReDay, { movimentiCassa });
        updateDoc(addReDay, { totaleMovimentiCassa });
        updateDoc(addReDay, { totaleCreditiGiorno });
        updateDoc(addReDay, { arraySpese });
        updateDoc(addReDay, { totaleSpese });
        updateDoc(addReDay, { aggioGiornaliero });

        // aggiungo tutte le voci cassa gia esistenti nella lista
        arrayVociCassa.forEach(async (voce) => {
          const addRefVociSingola = doc(addReDay, "voci-cassa", voce.alias);
          await setDoc(addRefVociSingola, {
            alias: voce.alias,
            nome: voce.nome,
            valore: Number(valore.toFixed(2)),
            percentualeQuadagno: Number(voce.percentualeQuadagno),
          });

          arrayVociCassa = await searchVociCassaSingleDay(day);
        });
      } else {
        // se il giorno già esiste
        // totale cassa
        totaleCassa = data._document.data.value.mapValue.fields.totaleCassa.doubleValue
          ? data._document.data.value.mapValue.fields.totaleCassa.doubleValue
          : data._document.data.value.mapValue.fields.totaleCassa.integerValue;
        totaleCreditiGiorno = data._document.data.value.mapValue.fields
          .totaleCreditiGiorno.doubleValue
          ? data._document.data.value.mapValue.fields.totaleCreditiGiorno.doubleValue
          : data._document.data.value.mapValue.fields.totaleCreditiGiorno.integerValue;
        totaleMovimentiCassa = data._document.data.value.mapValue.fields
          .totaleMovimentiCassa.doubleValue
          ? data._document.data.value.mapValue.fields.totaleMovimentiCassa.doubleValue
          : data._document.data.value.mapValue.fields.totaleMovimentiCassa.integerValue;
        totaleSpese = data._document.data.value.mapValue.fields.totaleSpese.doubleValue
          ? data._document.data.value.mapValue.fields.totaleSpese.doubleValue
          : data._document.data.value.mapValue.fields.totaleSpese.integerValue;
        // array di stringhe che contiene i crediti del giorno
        creditiGiorno =
          data._document.data.value.mapValue.fields.creditiGiorno.arrayValue.values.map(
            (item) => item.stringValue // mappato per le string value
          );

        // array che contine i movimentiCassa
        movimentiCassa =
          data._document.data.value.mapValue.fields.movimentiCassa.arrayValue.values.map(
            (item) => item.stringValue
          );

        // array che contiene le spese del giorno
        arraySpese =
          data._document.data.value.mapValue.fields.arraySpese.arrayValue.values.map(
            (item) => item.stringValue
          );

        if (!creditiGiorno.includes("false")) {
          creditiGiorno =
            data._document.data.value.mapValue.fields.creditiGiorno.arrayValue.values;

          creditiGiorno = await convertToArrayOfObject(creditiGiorno);

          creditiGiorno = await updateTotal(creditiGiorno);

          await updateDoc(addReDay, { creditiGiorno });
        }
        if (!movimentiCassa.includes("false")) {
          movimentiCassa =
            data._document.data.value.mapValue.fields.movimentiCassa.arrayValue.values;
          movimentiCassa = await convertMovCassaToArrayOfObject(movimentiCassa);
        }
        if (!arraySpese.includes("false")) {
          arraySpese =
            data._document.data.value.mapValue.fields.arraySpese.arrayValue.values;
          arraySpese = await convertMovCassaToArrayOfObject(arraySpese);
        }
        // lista voci cassa de
        arrayVociCassa = await searchVociCassaSingleDay(day);
        listaVociCassa = await searchVociCassa();

        const resCompare = await compareArrayVociCassa(arrayVociCassa, listaVociCassa);
        arrayVociCassa = resCompare[0];
        const vociDaAggiungere = resCompare[1];

        vociDaAggiungere.forEach(async (add) => {
          let dataDoc = {
            alias: add.alias,
            nome: add.nome,
            valore: Number(valore.toFixed(2)),
            percentualeQuadagno: Number(add.percentualeQuadagno),
          };
          const addRefVociSingola = doc(addReDay, "voci-cassa", add.alias);
          await setDoc(addRefVociSingola, dataDoc);
        });

        arrayVociCassa = await searchVociCassaSingleDay(day);

        // fetch aggio giornaliero
        let accGuadagno = 0;
        arrayVociCassa.forEach((item) => {
          if (item.percentualeQuadagno !== "0") {
            // console.log(item.percentualeQuadagno);
            // console.log(item.valore);
            accGuadagno =
              accGuadagno +
              Number(
                ((Number(item.percentualeQuadagno) * Number(item.valore)) / 100).toFixed(
                  2
                )
              );
          }
        });

        aggioGiornaliero = accGuadagno.toFixed(2);
        updateDoc(addReDay, { aggioGiornaliero });
      }

      dispatch(
        fetchDayTotalSuccess(
          totaleCassa,
          movimentiCassa,
          creditiGiorno,
          arrayVociCassa,
          totaleCreditiGiorno,
          totaleMovimentiCassa,
          arraySpese,
          totaleSpese,
          aggioGiornaliero
        )
      );
    } catch (error) {
      dispatch(fetchDayTotalFail(error));
      console.log(error);
    }
  };
};
export const fetchDayTotalStart = () => {
  return {
    type: FETCH_DAY_TOTAL_START,
  };
};

export const fetchDayTotalSuccess = (
  totaleCassa,
  movimentiCassa,
  creditiGiorno,
  arrayVociCassa,
  totaleCreditiGiorno,
  totaleMovimentiCassa,
  arraySpese,
  totaleSpese,
  aggioGiornaliero
) => {
  return {
    type: FETCH_DAY_TOTAL_SUCCESS,
    //action
    totaleCassa,
    movimentiCassa,
    creditiGiorno,
    arrayVociCassa,
    totaleCreditiGiorno,
    totaleMovimentiCassa,
    arraySpese,
    totaleSpese,
    aggioGiornaliero,
  };
};

export const fetchDayTotalFail = (error) => {
  return {
    type: FETCH_DAY_TOTAL_FAIL,
    error: error,
  };
};

export const addCredito = (cognome, nome, importo, day) => {
  return async (dispatch, getState) => {
    dispatch(addCreditoStart());
    importo = Number(importo.toFixed(2));
    //logic function
    let credititoTotale = 0;
    let storicoCrediti = [];
    let creditiGiorno = [];
    let totaleCreditiGiorno = 0;
    let totaleCassa = 0;

    // riferimento univco del magazzino Utente
    const email = localStorage.getItem("email");
    const refMag = email.split("@");
    const addRefRubrica = doc(db, `${refMag[0]}.magazzino`, "rubrica"); // riferimento del db nello rubrica
    const addRefNomi = doc(addRefRubrica, "rubrica", cognome + "-" + nome); // rif biglietti non ancora venduti

    const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello caledario
    const addReDay = doc(addRefCalendario, "giorni", day); // rif caledario giorno specifo

    // creo il riferimento per la rubrica
    let dataName = await getDoc(addRefNomi);
    const nameExist = dataName.exists();
    //cerco i dati del giorno per aggiornare l'array creditiGiorno
    let dataCreditiGiorno = await getDoc(addReDay);
    creditiGiorno =
      dataCreditiGiorno._document.data.value.mapValue.fields.creditiGiorno.arrayValue.values.map(
        (item) => item.stringValue
      );
    totaleCreditiGiorno = dataCreditiGiorno._document.data.value.mapValue.fields
      .totaleCreditiGiorno.doubleValue
      ? dataCreditiGiorno._document.data.value.mapValue.fields.totaleCreditiGiorno
          .doubleValue
      : dataCreditiGiorno._document.data.value.mapValue.fields.totaleCreditiGiorno
          .integerValue;
    totaleCassa = dataCreditiGiorno._document.data.value.mapValue.fields.totaleCassa
      .doubleValue
      ? dataCreditiGiorno._document.data.value.mapValue.fields.totaleCassa.doubleValue
      : dataCreditiGiorno._document.data.value.mapValue.fields.totaleCassa.integerValue;

    if (!creditiGiorno.includes("false")) {
      creditiGiorno =
        dataCreditiGiorno._document.data.value.mapValue.fields.creditiGiorno.arrayValue
          .values;
      creditiGiorno = await convertToArrayOfObject(creditiGiorno);
    }
    if (nameExist === false) {
      // il nome non è in rubrica quindi va aggiunto prima aggiunto prima
      dispatch(addCreditoFail(true, " Nome non presente in rubrica aggiungilo"));
    } else {
      // il nome è gia presente in rubrica quindi posso procedere
      var time = new Date().toLocaleTimeString().slice(0, 5); // creo l'ora del credito
      credititoTotale = Number(dataName.data().credititoTotale) + importo; // creditoTotale precedente + importo credito corrente
      storicoCrediti = dataName.data().storicoCrediti; // storico crediti precedente
      totaleCreditiGiorno = Number(totaleCreditiGiorno) + Number(importo);
      totaleCassa = Number(totaleCassa) + Number(-importo);

      credititoTotale = Number(credititoTotale.toFixed(2));
      totaleCassa = Number(totaleCassa.toFixed(2));
      totaleCreditiGiorno = Number(totaleCreditiGiorno.toFixed(2));

      // se include false lo elimino false per inizializzare l'array
      if (storicoCrediti.includes("false")) {
        storicoCrediti = [];
      }

      // se creditiGionro contiene false allora è vuoto quindi lo inizializzo
      if (creditiGiorno.includes("false")) {
        creditiGiorno = []; // inizializzo
        storicoCrediti.push(`${day}/${time}/${importo}`); // aggiungo la stringa nello storico crediti

        creditiGiorno.push({
          id: `${cognome}-${nome}`,
          cognome: cognome.toUpperCase(),
          nome: nome.toUpperCase(),
          totaleCG: String(importo.toFixed(2)),
          time: time,
          credititoTotale: String(credititoTotale.toFixed(2)),
        });

        // aggiungo la strigna del primo credito
      } else {
        // In creditigiorno sono gia presenti altri crediti
        storicoCrediti.push(`${day}/${time}/${importo}`); // aggiungo lo storico crediti del singolo cliente

        // controllo se esistono duplicati
        let res = await ReplaceTotalToDuplicateName(
          creditiGiorno,
          cognome,
          nome,
          Number(importo.toFixed(2)),
          Number(credititoTotale.toFixed(2)),
          time
        );

        creditiGiorno = res[1];
        let eraPresente = res[0];

        if (eraPresente === false) {
          creditiGiorno.push({
            id: cognome + "-" + nome,
            cognome: cognome.toUpperCase(),
            nome: nome.toUpperCase(),
            totaleCG: String(importo.toFixed(2)),
            time: time,
            credititoTotale: String(credititoTotale.toFixed(2)),
          });
        }

        creditiGiorno = await updateTotal(creditiGiorno); // aggiorno i totali
      }

      updateDoc(addRefNomi, { credititoTotale });
      updateDoc(addRefNomi, { storicoCrediti });

      await updateDoc(addReDay, { creditiGiorno });
      await updateDoc(addReDay, { totaleCreditiGiorno });
      await updateDoc(addReDay, { totaleCassa });

      await dispatch(addCreditoSuccess(creditiGiorno, totaleCreditiGiorno, totaleCassa));
      // await dispatch(fetchDayTotal(day));
    }
  };
};

export const addCreditoStart = () => {
  return {
    type: ADD_CREDITO_START,
  };
};

export const addCreditoSuccess = (creditiGiorno, totaleCreditiGiorno, totaleCassa) => {
  return {
    type: ADD_CREDITO_SUCCESS,
    //action
    creditiGiorno,
    totaleCreditiGiorno,
    totaleCassa,
  };
};

export const addCreditoFail = (error, message) => {
  return {
    type: ADD_CREDITO_FAIL,
    error: error,
    message: message,
  };
};
export const addMovCassa = (importo, infoMov, day) => {
  return async (dispatch, getState) => {
    dispatch(addMovCassaStart());

    //logic function
    let movimentiCassa = 0;
    let totaleMovimentiCassa = 0;
    let totaleCassa = 0;
    const unique_id = uuid();
    importo = Number(importo);

    // riferimento univco del magazzino Utente
    const email = localStorage.getItem("email");
    const refMag = email.split("@");
    const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello caledario
    const addReDay = doc(addRefCalendario, "giorni", day); // rif caledario giorno specifo

    // verifico che esista gia un totale per quel giorno e se non esiste ne creo uno
    let data = await getDoc(addReDay);
    const dExist = data.exists();
    if (dExist === false) {
      dispatch(
        addMovCassaFail(true, `Ricarica la pagina c'è stato un erorre improvviso`)
      );
    } else {
      var time = new Date().toLocaleTimeString().slice(0, 5); // creo l'ora del movCassa
      // array che contine i movimentiCassa
      movimentiCassa =
        data._document.data.value.mapValue.fields.movimentiCassa.arrayValue.values.map(
          (item) => item.stringValue
        );
      totaleMovimentiCassa = data._document.data.value.mapValue.fields
        .totaleMovimentiCassa.doubleValue
        ? data._document.data.value.mapValue.fields.totaleMovimentiCassa.doubleValue
        : data._document.data.value.mapValue.fields.totaleMovimentiCassa.integerValue;
      totaleCassa = data._document.data.value.mapValue.fields.totaleCassa.doubleValue
        ? data._document.data.value.mapValue.fields.totaleCassa.doubleValue
        : data._document.data.value.mapValue.fields.totaleCassa.integerValue;

      totaleMovimentiCassa = Number(totaleMovimentiCassa) + Number(importo);
      totaleCassa = Number(totaleCassa) + Number(-importo); // totale cassa negativo
      totaleMovimentiCassa = Number(totaleMovimentiCassa.toFixed(2));
      totaleCassa = Number(totaleCassa.toFixed(2));

      if (movimentiCassa.includes("false")) {
        movimentiCassa = []; // lo inizializzo
        movimentiCassa.push({
          importo: String(importo.toFixed(2)),
          infoMov: infoMov + " : " + time,
          id: unique_id,
        });
      } else {
        movimentiCassa =
          data._document.data.value.mapValue.fields.movimentiCassa.arrayValue.values;
        movimentiCassa = await convertMovCassaToArrayOfObject(movimentiCassa);
        movimentiCassa.push({
          importo: String(importo.toFixed(2)),
          infoMov: infoMov + " : " + time,
          id: unique_id,
        });
      }

      await updateDoc(addReDay, { movimentiCassa });
      await updateDoc(addReDay, { totaleMovimentiCassa });
      await updateDoc(addReDay, { totaleCassa });
      await dispatch(
        addMovCassaSuccess(movimentiCassa, totaleMovimentiCassa, totaleCassa)
      );
    }
  };
};

export const addMovCassaStart = () => {
  return {
    type: ADD_MOV_CASSA_START,
  };
};

export const addMovCassaSuccess = (movimentiCassa, totaleMovimentiCassa, totaleCassa) => {
  return {
    type: ADD_MOV_CASSA_SUCCESS,
    //action
    movimentiCassa,
    totaleMovimentiCassa,
    totaleCassa,
  };
};

export const addMovCassaFail = (error, message) => {
  return {
    type: ADD_MOV_CASSA_FAIL,
    error: error,
    message: message,
  };
};

export const addSpesa = (importo, infoMov, day) => {
  return async (dispatch, getState) => {
    dispatch(addSpesaStart());

    //logic function
    let arraySpese = [];
    let totaleSpese = 0;
    importo = Number(importo);

    let totaleCassa = 0;

    const unique_id = uuid();

    // riferimento univco del magazzino Utente
    const email = localStorage.getItem("email");
    const refMag = email.split("@");
    const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello caledario
    const addReDay = doc(addRefCalendario, "giorni", day); // rif caledario giorno specifo

    // verifico che esista gia un totale per quel giorno e se non esiste ne creo uno
    let data = await getDoc(addReDay);
    const dExist = data.exists();
    if (dExist === false) {
      dispatch(addSpesaFail(true, `Ricarica la pagina c'è stato un erorre improvviso`));
    } else {
      var time = new Date().toLocaleTimeString().slice(0, 5); // creo l'ora del movCassa
      // array che contine i Spese
      arraySpese =
        data._document.data.value.mapValue.fields.arraySpese.arrayValue.values.map(
          (item) => item.stringValue
        );
      totaleSpese = data._document.data.value.mapValue.fields.totaleSpese.doubleValue
        ? data._document.data.value.mapValue.fields.totaleSpese.doubleValue
        : data._document.data.value.mapValue.fields.totaleSpese.integerValue;
      totaleCassa = data._document.data.value.mapValue.fields.totaleCassa.doubleValue
        ? data._document.data.value.mapValue.fields.totaleCassa.doubleValue
        : data._document.data.value.mapValue.fields.totaleCassa.integerValue;

      totaleSpese = Number(totaleSpese) + Number(importo);
      totaleCassa = Number(totaleCassa) + Number(-importo); // negativo
      totaleSpese = Number(totaleSpese.toFixed(2));
      totaleCassa = Number(totaleCassa.toFixed(2));

      if (arraySpese.includes("false")) {
        arraySpese = []; // lo inizializzo
        arraySpese.push({
          importo: String(importo.toFixed(2)),
          infoMov: infoMov + " : " + time,
          id: unique_id,
        });
      } else {
        arraySpese =
          data._document.data.value.mapValue.fields.arraySpese.arrayValue.values;
        arraySpese = await convertMovCassaToArrayOfObject(arraySpese);
        arraySpese.push({
          importo: String(importo.toFixed(2)),
          infoMov: infoMov + " : " + time,
          id: unique_id,
        });
      }

      await updateDoc(addReDay, { arraySpese });
      await updateDoc(addReDay, { totaleSpese });
      await updateDoc(addReDay, { totaleCassa });
      await dispatch(addSpesaSuccess(arraySpese, totaleSpese, totaleCassa));
    }
  };
};

export const addSpesaStart = () => {
  return {
    type: ADD_SPESA_START,
  };
};

export const addSpesaSuccess = (arraySpese, totaleSpese, totaleCassa) => {
  return {
    type: ADD_SPESA_SUCCESS,
    //action
    arraySpese,
    totaleSpese,
    totaleCassa,
  };
};

export const addSpesaFail = (error, message) => {
  return {
    type: ADD_SPESA_FAIL,
    error: error,
    message: message,
  };
};

export const deleteMovCassa = (id, importo, day) => {
  return async (dispatch, getState) => {
    dispatch(deleteMovCassaStart());

    //logic function
    let totaleMovimentiCassa = 0;
    importo = Number(importo);
    let totaleCassa = 0;
    let movimentiCassa = await getState().homeReducers.movimentiCassa;

    // riferimento univco del magazzino Utente
    const email = localStorage.getItem("email");
    const refMag = email.split("@");
    const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello caledario
    const addReDay = doc(addRefCalendario, "giorni", day); // rif caledario giorno specifo

    // verifico che esista gia un totale per quel giorno e se non esiste ne creo uno
    let data = await getDoc(addReDay);
    const dExist = data.exists();
    if (dExist === false) {
      dispatch(
        deleteMovCassaFail(true, `Ricarica la pagina c'è stato un erorre improvviso`)
      );
    } else {
      // array che contine i movimentiCassa
      totaleMovimentiCassa = data._document.data.value.mapValue.fields
        .totaleMovimentiCassa.doubleValue
        ? data._document.data.value.mapValue.fields.totaleMovimentiCassa.doubleValue
        : data._document.data.value.mapValue.fields.totaleMovimentiCassa.integerValue;
      totaleCassa = data._document.data.value.mapValue.fields.totaleCassa.doubleValue
        ? data._document.data.value.mapValue.fields.totaleCassa.doubleValue
        : data._document.data.value.mapValue.fields.totaleCassa.integerValue;

      totaleMovimentiCassa = Number(totaleMovimentiCassa) - Number(importo);
      totaleCassa = Number(totaleCassa) - Number(-importo); // negativo
      totaleMovimentiCassa = Number(totaleMovimentiCassa.toFixed(2));
      totaleCassa = Number(totaleCassa.toFixed(2));

      movimentiCassa = await RemoveArrayObjectoForID(movimentiCassa, id);

      if (movimentiCassa.length === 0) {
        movimentiCassa.push("false");
      }

      await updateDoc(addReDay, { movimentiCassa });
      await updateDoc(addReDay, { totaleMovimentiCassa });
      await updateDoc(addReDay, { totaleCassa });
      await dispatch(
        deleteMovCassaSuccess(movimentiCassa, totaleMovimentiCassa, totaleCassa)
      );
    }
  };
};

export const deleteMovCassaStart = () => {
  return {
    type: DELETE_MOV_CASSA_START,
  };
};

export const deleteMovCassaSuccess = (
  movimentiCassa,
  totaleMovimentiCassa,
  totaleCassa
) => {
  return {
    type: DELETE_MOV_CASSA_SUCCESS,
    //action
    movimentiCassa,
    totaleMovimentiCassa,
    totaleCassa,
  };
};

export const deleteMovCassaFail = (error, message) => {
  return {
    type: DELETE_MOV_CASSA_FAIL,
    error: error,
    message: message,
  };
};

export const deleteSpesa = (id, importo, day) => {
  return async (dispatch, getState) => {
    dispatch(deleteSpesaStart());

    //logic function
    let totaleSpese = 0;
    importo = Number(importo);
    let totaleCassa = 0;
    let arraySpese = await getState().homeReducers.arraySpese;

    // riferimento univco del magazzino Utente
    const email = localStorage.getItem("email");
    const refMag = email.split("@");
    const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello caledario
    const addReDay = doc(addRefCalendario, "giorni", day); // rif caledario giorno specifo

    // verifico che esista gia un totale per quel giorno e se non esiste ne creo uno
    let data = await getDoc(addReDay);
    const dExist = data.exists();
    if (dExist === false) {
      dispatch(
        deleteSpesaFail(true, `Ricarica la pagina c'è stato un erorre improvviso`)
      );
    } else {
      // array che contine i movimentiCassa
      totaleSpese = data._document.data.value.mapValue.fields.totaleSpese.doubleValue
        ? data._document.data.value.mapValue.fields.totaleSpese.doubleValue
        : data._document.data.value.mapValue.fields.totaleSpese.integerValue;
      totaleCassa = data._document.data.value.mapValue.fields.totaleCassa.doubleValue
        ? data._document.data.value.mapValue.fields.totaleCassa.doubleValue
        : data._document.data.value.mapValue.fields.totaleCassa.integerValue;

      totaleSpese = Number(totaleSpese) - Number(importo);
      totaleCassa = Number(totaleCassa) - Number(-importo); // negativo
      totaleSpese = Number(totaleSpese.toFixed(2));
      totaleCassa = Number(totaleCassa.toFixed(2));

      arraySpese = await RemoveArrayObjectoForID(arraySpese, id);

      if (arraySpese.length === 0) {
        arraySpese.push("false");
      }

      await updateDoc(addReDay, { arraySpese });
      await updateDoc(addReDay, { totaleSpese });
      await updateDoc(addReDay, { totaleCassa });
      await dispatch(deleteSpesaSuccess(arraySpese, totaleSpese, totaleCassa));
    }
  };
};

export const deleteSpesaStart = () => {
  return {
    type: DELETE_SPESA_START,
  };
};

export const deleteSpesaSuccess = (arraySpese, totaleSpese, totaleCassa) => {
  return {
    type: DELETE_SPESA_SUCCESS,
    //action
    arraySpese,
    totaleSpese,
    totaleCassa,
  };
};

export const deleteSpesaFail = (error, message) => {
  return {
    type: DELETE_SPESA_FAIL,
    error: error,
    message: message,
  };
};

export const addVoceCassa = (alias, nome, percentualeQuadagno, day) => {
  return async (dispatch, getState) => {
    dispatch(addVoceCassaStart());

    //logic function
    let arrayVociCassa = [];
    let valore = 0;

    // riferimento univco del magazzino Utente
    const email = localStorage.getItem("email");
    const refMag = email.split("@");
    const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello caledario
    const addRefVoceCassa = doc(addRefCalendario, "lista-voci-cassa", alias); // rif biglietti non ancora venduti
    const addReDay = doc(addRefCalendario, "giorni", day); // rif caledario giorno specifo

    // creo il riferimento per la rubrica
    let dataVoceCassa = await getDoc(addRefVoceCassa);
    const dataVoceCassaExsit = dataVoceCassa.exists();
    if (dataVoceCassaExsit) {
      dispatch(addVoceCassaFail(true, "la voce è gia esistente"));
    } else {
      setDoc(addRefVoceCassa, {
        alias: alias,
        nome: nome,
        percentualeQuadagno: percentualeQuadagno,
      });
      const addRefVociSingola = doc(addReDay, "voci-cassa", alias);
      await setDoc(addRefVociSingola, {
        alias: alias,
        nome: nome,
        valore: Number(valore.toFixed(2)),
        percentualeQuadagno: Number(percentualeQuadagno),
      });
      arrayVociCassa = await searchVociCassaSingleDay(day);
    }

    await dispatch(addVoceCassaSuccess(arrayVociCassa));
  };
};

export const addVoceCassaStart = () => {
  return {
    type: ADD_VOCE_CASSA_START,
  };
};

export const addVoceCassaSuccess = (arrayVociCassa) => {
  return {
    type: ADD_VOCE_CASSA_SUCCESS,
    //action
    arrayVociCassa,
  };
};

export const addVoceCassaFail = (error, message) => {
  return {
    type: ADD_VOCE_CASSA_FAIL,
    error: error,
    message: message,
  };
};
export const addVoceCassaSave = (alias, valore, day) => {
  return async (dispatch, getState) => {
    dispatch(addVoceCassaSaveStart());
    let totaleCassa = 0;
    let aggioGiornaliero = 0;

    valore = Number(valore.toFixed(2));
    // riferimento univco del magazzino Utente
    const email = localStorage.getItem("email");
    const refMag = email.split("@");
    const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello caledario
    const addReDay = doc(addRefCalendario, "giorni", day); // rif caledario giorno specifo

    const addRefVociSingola = doc(addReDay, "voci-cassa", alias);
    let dataVoceCassa = await getDoc(addRefVociSingola);

    let valoreVecchio = dataVoceCassa._document.data.value.mapValue.fields.valore
      .doubleValue
      ? dataVoceCassa._document.data.value.mapValue.fields.valore.doubleValue
      : dataVoceCassa._document.data.value.mapValue.fields.valore.integerValue;

    let data = await getDoc(addReDay);
    totaleCassa = data._document.data.value.mapValue.fields.totaleCassa.doubleValue
      ? data._document.data.value.mapValue.fields.totaleCassa.doubleValue
      : data._document.data.value.mapValue.fields.totaleCassa.integerValue;
    if (Number(valoreVecchio) === valore) {
      dispatch(
        addVoceCassaSaveFail(
          true,
          "Il valore è uguale a quello precedente, per salvarlo devi prima modificarlo"
        )
      );
    } else {
      console.log("totale cassa => " + totaleCassa);
      console.log("valore => " + valore);
      console.log("valore vecchio => " + valoreVecchio);

      let tempCalc = Number(valore) - Number(valoreVecchio);
      console.log("tempCalc => " + tempCalc);
      totaleCassa = Number(totaleCassa) + Number(-tempCalc);
      totaleCassa = Number(totaleCassa.toFixed(2));

      console.log("totale cassa dopo operazione => " + totaleCassa);
      // creo il riferimento per la rubrica

      const dataVoceCassaExsit = dataVoceCassa.exists();
      if (!dataVoceCassaExsit) {
        dispatch(addVoceCassaSaveFail(true, "la voce non è esistente"));
      } else {
        updateDoc(addRefVociSingola, { valore });
      }
      updateDoc(addReDay, { totaleCassa });

      const arrayVociCassa = await searchVociCassaSingleDay(day);
      // fetch aggio giornaliero
      let accGuadagno = 0;
      arrayVociCassa.forEach((item) => {
        if (item.percentualeQuadagno !== "0") {
          // console.log(item.percentualeQuadagno);
          // console.log(item.valore);
          accGuadagno =
            accGuadagno +
            Number(
              ((Number(item.percentualeQuadagno) * Number(item.valore)) / 100).toFixed(2)
            );
        }
      });

      aggioGiornaliero = accGuadagno.toFixed(2);
      updateDoc(addReDay, { aggioGiornaliero });

      await dispatch(
        addVoceCassaSaveSuccess(totaleCassa, aggioGiornaliero, arrayVociCassa)
      );
    }
    // await dispatch(fetchDayTotal(day)); // controllare perchè non si cambia il colore della voce cassa
  };
};

export const addVoceCassaSaveStart = () => {
  return {
    type: ADD_VOCE_CASSA_SAVE_START,
  };
};

export const addVoceCassaSaveSuccess = (
  totaleCassa,
  aggioGiornaliero,
  arrayVociCassa
) => {
  return {
    type: ADD_VOCE_CASSA_SAVE_SUCCESS,
    //action
    totaleCassa,
    aggioGiornaliero,
    arrayVociCassa,
  };
};

export const addVoceCassaSaveFail = (error, message) => {
  return {
    type: ADD_VOCE_CASSA_SAVE_FAIL,
    error: error,
    message: message,
  };
};
export const addVoceCassaDelete = (alias, day) => {
  return async (dispatch, getState) => {
    dispatch(addVoceCassaDeleteStart());
    let arrayVociCassa = [];

    // riferimento univco del magazzino Utente
    const email = localStorage.getItem("email");
    const refMag = email.split("@");
    const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello caledario
    const addReDay = doc(addRefCalendario, "giorni", day); // rif caledario giorno specifo

    const addRefVociSingola = doc(addReDay, "voci-cassa", alias); // rif voce cassa singola
    const addRefVoceCassa = doc(addRefCalendario, "lista-voci-cassa", alias); // rif voce cassa della lista generica

    let dataVoceCassa = await getDoc(addRefVociSingola);

    let valoreVecchio = dataVoceCassa._document.data.value.mapValue.fields.valore
      .doubleValue
      ? dataVoceCassa._document.data.value.mapValue.fields.valore.doubleValue
      : dataVoceCassa._document.data.value.mapValue.fields.valore.integerValue;

    let data = await getDoc(addReDay);
    let totaleCassa = data._document.data.value.mapValue.fields.totaleCassa.doubleValue
      ? data._document.data.value.mapValue.fields.totaleCassa.doubleValue
      : data._document.data.value.mapValue.fields.totaleCassa.integerValue;

    totaleCassa = Number(totaleCassa) - Number(-valoreVecchio);
    totaleCassa = Number(totaleCassa.toFixed(2));

    // creo il riferimento per la voce della cassa sulla lista
    let dataVoceCassaLista = await getDoc(addRefVoceCassa);
    const dataVoceCassaListaExsit = dataVoceCassaLista.exists();
    if (!dataVoceCassaListaExsit) {
      dispatch(addVoceCassaDeleteFail(true, "la voce è gia stata eliminata"));
    } else {
      deleteDoc(addRefVoceCassa);
      deleteDoc(addRefVociSingola);
      arrayVociCassa = await searchVociCassaSingleDay(day);
      updateDoc(addReDay, { totaleCassa });
      await dispatch(addVoceCassaDeleteSuccess(arrayVociCassa, totaleCassa));
    }
  };
};

export const addVoceCassaDeleteStart = () => {
  return {
    type: ADD_VOCE_CASSA_DELETE_START,
  };
};

export const addVoceCassaDeleteSuccess = (arrayVociCassa, totaleCassa) => {
  return {
    type: ADD_VOCE_CASSA_DELETE_SUCCESS,
    arrayVociCassa,
    totaleCassa,
    //action
  };
};

export const addVoceCassaDeleteFail = (error, message) => {
  return {
    type: ADD_VOCE_CASSA_DELETE_FAIL,
    error: error,
    message: message,
  };
};
export const errorReset = () => {
  return {
    type: ERROR_RESET,
  };
};

export {
  ADD_NAME_START,
  ADD_NAME_SUCCESS,
  ADD_NAME_FAIL,
  FETCH_DAY_TOTAL_START,
  FETCH_DAY_TOTAL_FAIL,
  FETCH_DAY_TOTAL_SUCCESS,
  ADD_CREDITO_START,
  ADD_CREDITO_SUCCESS,
  ADD_CREDITO_FAIL,
  ADD_MOV_CASSA_START,
  ADD_MOV_CASSA_SUCCESS,
  ADD_MOV_CASSA_FAIL,
  DELETE_MOV_CASSA_START,
  DELETE_MOV_CASSA_SUCCESS,
  DELETE_MOV_CASSA_FAIL,
  ADD_VOCE_CASSA_START,
  ADD_VOCE_CASSA_SUCCESS,
  ADD_VOCE_CASSA_FAIL,
  ADD_VOCE_CASSA_SAVE_START,
  ADD_VOCE_CASSA_SAVE_SUCCESS,
  ADD_VOCE_CASSA_SAVE_FAIL,
  ADD_VOCE_CASSA_DELETE_START,
  ADD_VOCE_CASSA_DELETE_SUCCESS,
  ADD_VOCE_CASSA_DELETE_FAIL,
  ADD_SPESA_START,
  ADD_SPESA_SUCCESS,
  ADD_SPESA_FAIL,
  DELETE_SPESA_START,
  DELETE_SPESA_SUCCESS,
  DELETE_SPESA_FAIL,
  ERROR_RESET,
};
