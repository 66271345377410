import React from "react";
import Faq from "react-faq-component";
import { useSelector } from "react-redux";
import { data } from "../components/DataHelp";
import Navbar from "../components/Navbar";
import "../styles/Help.css";

function Help() {
  const userToken = useSelector((state) => state.authReducer.token);
  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <div className="help">
        <Faq
          data={data}
          styles={{
            bgColor: "white",
            titleTextColor: "#4c79d8",
            rowTitleColor: "#2b3041",
            rowTitleTextSize: "large",
            rowContentColor: "2b3041",
            rowContentTextSize: "16px",
            rowContentPaddingTop: "10px",
            rowContentPaddingBottom: "10px",
            rowContentPaddingLeft: "50px",
            rowContentPaddingRight: "150px",
            arrowColor: "2b3041",
          }}
        />
      </div>
    </>
  );
}

export default Help;
