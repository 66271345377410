import React from "react";
import { useDispatch } from "react-redux";
import {
  deleteNameRubrica,
  updateStoricoCrediti,
} from "../../store/actions/handelListaCrediti";
import "./CreditCard.css";
import { StyleColorObject } from "../../functions/StyleColorObject";
import IcoDelete from "../globalComponent/IcoDelete";
import Swal from "sweetalert2";

function CreditCard({ item, index }) {
  const dispatch = useDispatch();
  const styles = StyleColorObject(Number(item.credititoTotale));

  const showStoricoCrediti = () => {
    dispatch(updateStoricoCrediti(item.storicoCrediti));
  };

  const handelConfirmDelete = () => {
    Swal.fire({
      title: `Sei sicuro di voler eliminare questo nome ??`,
      text: `${item.cognome.toUpperCase()} ${item.nome.toUpperCase()} verra eliminato`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00D100",
      cancelButtonColor: "#d33",
      confirmButtonText: "SI, ELIMINA !!",
      cancelButtonText: "NO",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteNameRubrica(item.alias));
      }
    });
  };
  return (
    <>
      {/* <div className="credit-card">
        <div
          className="card-value"
          onClick={showStoricoCrediti}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <p className="cognome-card"></p>
          <p className="name-card"></p>
          <p className="value-card"></p>
        </div>
      </div> */}

      <tr
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Clicca per vedere lo storico dei movimenti"
        style={{ cursor: "pointer" }}
        onClick={showStoricoCrediti}
      >
        {/* <th scope="row">1</th>
        <td>{index}</td> */}
        <td>{item.cognome.toUpperCase()}</td>
        <td>{item.nome.toUpperCase()}</td>
        <td>
          <p style={styles}>{item.credititoTotale}</p>
        </td>

        <td>
          <IcoDelete handelConfirmDelete={handelConfirmDelete} />
        </td>
      </tr>
    </>
  );
}

export default CreditCard;
