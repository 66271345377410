import React from "react";
import "./HeadTot.css";
import { Divider } from "@mui/material";
function HeadTot({ title, value, style, marginLeft, marginTop, marginRight, icon }) {
  return (
    <div
      className="head-tot"
      style={{ marginLeft: marginLeft, marginTop: marginTop, marginRight: marginRight }}
    >
      <div className="icon_div">
        {icon}
        <Divider
          orientation="vertical"
          sx={{
            color: "#fff",
            bgcolor: "#fff",
            backgroundColor: "#fff",
            marginLeft: "10px",
          }}
        />
      </div>

      <div className="div_tot">
        <p className="total-value" style={{ color: "#fff" }}>
          {value} €
        </p>

        <p className="tot-text">{title}</p>
      </div>
    </div>
  );
}

export default HeadTot;
