import React from "react";
import "./CardSingleStats.css";
function CardSingleStats({ voce, tot, style }) {
  // if is true is '+' else is 'addName'
  return (
    <div className="container_single_card_stats">
      <p className="head_card_single_stats">{voce.nome}</p>
      <p className="totale_card_single_stats" style={style}>
        {((Number(tot.perQ) * Number(tot.totVoce)) / 100).toFixed(2)}€
      </p>
      <div className="footer_card_single_stats">
        <p style={{ marginRight: "5px" }}>Totale Lordo: </p>
        <p style={{ fontWeight: "bold" }}>{tot.totVoce}</p>
      </div>
    </div>
  );
}

export default CardSingleStats;
