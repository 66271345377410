import * as actionTypes from "../actions/handelVoiceSetting";

const initialState = {
  // error message
  loading: false,
  error: false,
  message: "",
  success: false,
  // store const
  arrVociCassa: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_VOICE_START:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case actionTypes.FETCH_VOICE_SUCCESS:
      return {
        ...state,
        //action

        arrVociCassa: action.arrVociCassa,

        //
        loading: false,
        error: false,
      };
    case actionTypes.FETCH_VOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.VOICE_SAVE_START:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case actionTypes.VOICE_SAVE_SUCCESS:
      return {
        ...state,
        //action

        //
        loading: false,
        error: false,
        success: true,
      };
    case actionTypes.VOICE_SAVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };
    case actionTypes.VOICE_DELETE_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.VOICE_DELETE_SUCCESS:
      return {
        ...state,
        //action

        //
        loading: false,
        error: false,
      };
    case actionTypes.VOICE_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.VOICE_ADD_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.VOICE_ADD_SUCCESS:
      return {
        ...state,
        //action

        //
        loading: false,
        error: false,
      };
    case actionTypes.VOICE_ADD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
