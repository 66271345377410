import * as actionTypes from "../actions/handleHome";

const initialState = {
  loading: false,
  error: false,
  message: "",
  creditiGiorno: [],
  totaleCassa: 0,
  movimentiCassa: [],
  arrayVociCassa: [],
  totaleCreditiGiorno: 0,
  totaleMovimentiCassa: 0,
  arraySpese: [],
  totaleSpese: false,
  loadingFetch: false,
  loadingCredit: false,
  loadingMovCassa: false,
  loadingSpese: false,
  loadingVociCassa: false,
  saveVociCassa: false,
  aggioGiornaliero: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_NAME_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.ADD_NAME_SUCCESS:
      return {
        ...state,
        //action
        message: "",
        //
        loading: false,
        error: false,
      };
    case actionTypes.ADD_NAME_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message,
      };

    case actionTypes.ADD_CREDITO_START:
      return {
        ...state,
        loading: true,
        error: false,
        loadingCredit: true,
      };
    case actionTypes.ADD_CREDITO_SUCCESS:
      return {
        ...state,
        creditiGiorno: action.creditiGiorno,
        totaleCreditiGiorno: action.totaleCreditiGiorno,
        totaleCassa: action.totaleCassa,
        //action
        message: "",
        //
        loading: false,
        loadingCredit: false,
        error: false,
      };
    case actionTypes.ADD_CREDITO_FAIL:
      return {
        ...state,
        loading: false,
        loadingCredit: false,
        error: true,
        message: action.message,
      };

    case actionTypes.ADD_SPESA_START:
      return {
        ...state,
        loading: true,
        loadingSpese: true,
        error: false,
      };
    case actionTypes.ADD_SPESA_SUCCESS:
      return {
        ...state,
        arraySpese: action.arraySpese,
        totaleSpese: action.totaleSpese,
        totaleCassa: action.totaleCassa,
        //action
        message: "",
        //
        loading: false,
        loadingSpese: false,
        error: false,
      };
    case actionTypes.ADD_SPESA_FAIL:
      return {
        ...state,
        loading: false,
        loadingSpese: false,
        error: true,
        message: action.message,
      };

    case actionTypes.DELETE_SPESA_START:
      return {
        ...state,
        loading: true,
        loadingSpese: true,
        error: false,
      };
    case actionTypes.DELETE_SPESA_SUCCESS:
      return {
        ...state,
        arraySpese: action.arraySpese,
        totaleSpese: action.totaleSpese,
        totaleCassa: action.totaleCassa,
        //action
        message: "",
        //
        loading: false,
        loadingSpese: false,
        error: false,
      };
    case actionTypes.DELETE_SPESA_FAIL:
      return {
        ...state,
        loading: false,
        loadingSpese: false,
        error: true,
        message: action.message,
      };

    case actionTypes.ADD_MOV_CASSA_START:
      return {
        ...state,
        loading: true,
        loadingMovCassa: true,
        error: false,
      };
    case actionTypes.ADD_MOV_CASSA_SUCCESS:
      return {
        ...state,
        movimentiCassa: action.movimentiCassa,
        totaleMovimentiCassa: action.totaleMovimentiCassa,
        totaleCassa: action.totaleCassa,
        //action
        message: "",
        //
        loading: false,
        loadingMovCassa: false,
        error: false,
      };
    case actionTypes.ADD_MOV_CASSA_FAIL:
      return {
        ...state,
        loading: false,
        loadingMovCassa: false,
        error: true,
        message: action.message,
      };

    case actionTypes.DELETE_MOV_CASSA_START:
      return {
        ...state,
        loading: true,
        loadingMovCassa: true,
        error: false,
      };
    case actionTypes.DELETE_MOV_CASSA_SUCCESS:
      return {
        ...state,
        movimentiCassa: action.movimentiCassa,
        totaleMovimentiCassa: action.totaleMovimentiCassa,
        totaleCassa: action.totaleCassa,
        //action
        message: "",
        //
        loading: false,
        loadingMovCassa: false,
        error: false,
      };
    case actionTypes.DELETE_MOV_CASSA_FAIL:
      return {
        ...state,
        loading: false,
        loadingMovCassa: false,
        error: true,
        message: action.message,
      };

    case actionTypes.FETCH_DAY_TOTAL_START:
      return {
        ...state,
        loading: true,
        loadingFetch: true,
        error: false,
      };
    case actionTypes.FETCH_DAY_TOTAL_SUCCESS:
      return {
        ...state,
        //action
        totaleCassa: action.totaleCassa,
        movimentiCassa: action.movimentiCassa,
        creditiGiorno: action.creditiGiorno,
        arrayVociCassa: action.arrayVociCassa,
        totaleCreditiGiorno: action.totaleCreditiGiorno,
        totaleMovimentiCassa: action.totaleMovimentiCassa,
        totaleSpese: action.totaleSpese,
        arraySpese: action.arraySpese,
        aggioGiornaliero: action.aggioGiornaliero,
        message: "",

        //
        loading: false,
        loadingFetch: false,
        error: false,
      };
    case actionTypes.FETCH_DAY_TOTAL_FAIL:
      return {
        ...state,
        loading: false,
        loadingFetch: false,
        error: action.error,
      };

    case actionTypes.ADD_VOCE_CASSA_START:
      return {
        ...state,
        loading: true,
        loadingVociCassa: true,
        error: false,
      };
    case actionTypes.ADD_VOCE_CASSA_SUCCESS:
      return {
        ...state,
        //action
        arrayVociCassa: action.arrayVociCassa,

        message: "",

        //
        loading: false,
        loadingVociCassa: false,
        error: false,
      };
    case actionTypes.ADD_VOCE_CASSA_FAIL:
      return {
        ...state,
        loading: false,
        loadingVociCassa: false,
        error: action.error,
      };

    case actionTypes.ADD_VOCE_CASSA_SAVE_START:
      return {
        ...state,
        loading: true,
        loadingVociCassa: true,
        saveVociCassa: false,
        error: false,
      };
    case actionTypes.ADD_VOCE_CASSA_SAVE_SUCCESS:
      return {
        ...state,
        //action
        message: "",
        totaleCassa: action.totaleCassa,
        aggioGiornaliero: action.aggioGiornaliero,
        arrayVociCassa: action.arrayVociCassa,

        //
        loading: false,
        loadingVociCassa: false,
        saveVociCassa: true,
        error: false,
      };
    case actionTypes.ADD_VOCE_CASSA_SAVE_FAIL:
      return {
        ...state,
        loading: false,
        loadingVociCassa: false,
        saveVociCassa: false,
        error: action.error,
        message: action.message,
      };

    case actionTypes.ADD_VOCE_CASSA_DELETE_START:
      return {
        ...state,
        loading: true,
        loadingVociCassa: true,
        error: false,
      };
    case actionTypes.ADD_VOCE_CASSA_DELETE_SUCCESS:
      return {
        ...state,
        arrayVociCassa: action.arrayVociCassa,
        totaleCassa: action.totaleCassa,
        //action
        message: "",

        //
        loading: false,
        loadingVociCassa: false,
        error: false,
      };
    case actionTypes.ADD_VOCE_CASSA_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        loadingVociCassa: false,
        error: action.error,
        message: action.message,
      };

    case actionTypes.ERROR_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        saveVociCassa: false,
      };

    default:
      return state;
  }
};

export default reducer;
