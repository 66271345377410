import React from "react";
import ReactTooltip from "react-tooltip";
import "./ButtonAdd.css";
import * as TiIcons from "react-icons/ti";
import * as ImIcons from "react-icons/im";

function ButtonAdd({ addFunction, type, dataTip, id, styleClass }) {
  // if is true is '+' else is 'addName'

  return (
    <button className={styleClass} onClick={addFunction}>
      {type ? (
        <TiIcons.TiPlus
          data-tip={dataTip}
          data-delay-show="200"
          data-for={id}
          className="svg-ico"
        />
      ) : (
        <ImIcons.ImUserPlus
          data-tip={dataTip}
          data-delay-show="200"
          data-for={id}
          className="svg-ico"
        />
      )}

      <ReactTooltip multiline={true} id={id} />
    </button>
  );
}

export default ButtonAdd;
