import * as actionTypes from "../actions/handelListaCrediti";

const initialState = {
  arrayRubrica: [],
  storicoCrediti: [],
  totCrediti: 0,
  loading: false,
  error: false,
  message: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RUBRICA_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.FETCH_RUBRICA_SUCCESS:
      return {
        ...state,
        //action
        arrayRubrica: action.arrayRubrica,
        totCrediti: action.totCrediti,
        //
        loading: false,
        error: false,
      };
    case actionTypes.FETCH_RUBRICA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actionTypes.ADD_NAME_RUBRICA_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.ADD_NAME_RUBRICA_SUCCESS:
      return {
        ...state,
        //action
        message: "",
        //
        loading: false,
        error: false,
      };
    case actionTypes.ADD_NAME_RUBRICA_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message,
      };
    case actionTypes.DELETE_NAME_RUBRICA_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.DELETE_NAME_RUBRICA_SUCCESS:
      return {
        ...state,
        //action
        message: "",
        //
        loading: false,
        error: false,
      };
    case actionTypes.DELETE_NAME_RUBRICA_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message,
      };
    case actionTypes.UPDATE_STORICO_CREDITI_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.UPDATE_STORICO_CREDITI_SUCCESS:
      return {
        ...state,
        storicoCrediti: action.storicoCrediti,
        //action
        message: "",
        //
        loading: false,
        error: false,
      };
    case actionTypes.UPDATE_STORICO_CREDITI_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message,
      };
    case actionTypes.UPDATE_ARRAY_RUBRICA:
      return {
        ...state,
        arrayRubrica: action.arrayRubrica,
      };

    default:
      return state;
  }
};

export default reducer;
