import React from "react";
import "./CardHeadReport.css";

function CardHeadReport({ nome }) {
  return (
    <div className="head-card-voce">
      <p>{nome}</p>
    </div>
  );
}

export default CardHeadReport;
