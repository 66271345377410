export const dateFix0 = async (date) => {
  // start fetch
  let dateArr = date.split("-");

  let giorno = dateArr[0];
  let mese = dateArr[1];
  if (dateArr[1].charAt(0) === "0") {
    mese = dateArr[1].charAt(1);
  }
  if (dateArr[0].charAt(0) === "0") {
    giorno = dateArr[0].charAt(1);
  }

  const dateFix = new Date(`${dateArr[2]}-${mese}-${giorno}`);

  return dateFix;
};
